import React, { useState, useEffect } from "react";
import { Card, Dropdown, Nav, Tab } from "react-bootstrap";
import { Table } from "antd";
import { Gift, Award, Calendar } from "react-feather";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { celebrations } from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";

const Celebrations = () => {
  const [activeTab, setActiveTab] = useState("birthdays");
  const [selectedMonth, setSelectedMonth] = useState(moment().format("M"));
  const [birthdays, setBirthdays] = useState([]);
  const [anniversaries, setAnniversaries] = useState([]);
  const [loading, setLoading] = useState(false);

  const months = moment.months().map((month, index) => ({
    value: (index + 1).toString(),
    label: month,
  }));

  const birthdayColumns = [
    {
      title: "Name",
      dataIndex: "employee_name",
      key: "employee_name",
      render: (text) => text || "N/A",
    },
    {
      title: "Birth Date",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      render: (date) => (date ? moment(date).format("DD MMM") : "N/A"),
    },
  ];

  const anniversaryColumns = [
    {
      title: "Name",
      dataIndex: ["employee_details", "employee_name"],
      key: "employee_name",
      render: (text) => text || "N/A",
    },
    {
      title: "Department",
      dataIndex: [
        "employee_details",
        "employment_details",
        0,
        "department_detail",
        "name",
      ],
      key: "department",
      render: (text) => text || "N/A",
    },
    {
      title: "Designation",
      dataIndex: [
        "employee_details",
        "employment_details",
        0,
        "designation_detail",
        "name",
      ],
      key: "designation",
      render: (text) => text || "N/A",
    },
    {
      title: "Join Date",
      dataIndex: "effective_date",
      key: "effective_date",
      render: (date) => (date ? moment(date).format("DD MMM YYYY") : "N/A"),
    },
    {
      title: "Years",
      dataIndex: "effective_date",
      key: "years",
      render: (date) => (date ? moment().diff(moment(date), "years") : "N/A"),
    },
  ];

  useEffect(() => {
    fetchCelebrations();
  }, [selectedMonth]);

  const fetchCelebrations = async () => {
    setLoading(true);
    try {
      const [birthdaysRes, anniversariesRes] = await Promise.all([
        axiosInstance.get(
          `${celebrations}?type=birthday&month=${selectedMonth}`
        ),
        axiosInstance.get(
          `${celebrations}?type=anniversary&month=${selectedMonth}`
        ),
      ]);

      setBirthdays(birthdaysRes.data);
      setAnniversaries(anniversariesRes.data);

      if (birthdaysRes.data.length === 0 && anniversariesRes.data.length > 0) {
        setActiveTab("anniversaries");
      }
    } catch (error) {
      console.error("Error fetching celebrations:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Celebrations</h3>
              </div>
              <div className="col-auto">
                <Dropdown>
                  <Dropdown.Toggle variant="light" id="month-filter">
                    <Calendar size={16} className="me-2" />
                    {months.find((m) => m.value === selectedMonth)?.label}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {months.map((month) => (
                      <Dropdown.Item
                        key={month.value}
                        onClick={() => setSelectedMonth(month.value)}
                        active={selectedMonth === month.value}
                      >
                        {month.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          <Card>
            <Card.Body>
              <Tab.Container activeKey={activeTab} onSelect={setActiveTab}>
                <Nav variant="tabs" className="mb-3">
                  <Nav.Item>
                    <Nav.Link eventKey="birthdays">
                      <Gift className="me-1" size={14} /> Birthdays
                      {birthdays.length > 0 && (
                        <span className="badge bg-primary ms-2">
                          {birthdays.length}
                        </span>
                      )}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="anniversaries"
                      style={{
                        ...(birthdays.length > 0 &&
                          anniversaries.length > 0 && {
                            backgroundColor: "#fff3cd",
                            color: "#ffc107",
                            fontWeight: "500",
                          }),
                      }}
                    >
                      <Award className="me-1" size={14} /> Anniversaries
                      {anniversaries.length > 0 && (
                        <span className="badge bg-primary ms-2">
                          {anniversaries.length}
                        </span>
                      )}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="birthdays">
                    <Table
                      columns={birthdayColumns}
                      dataSource={birthdays}
                      rowKey={(record) => record.id || Math.random()}
                      loading={loading}
                      pagination={false}
                      locale={{
                        emptyText: "No birthdays this month",
                      }}
                    />
                  </Tab.Pane>

                  <Tab.Pane eventKey="anniversaries">
                    <Table
                      columns={anniversaryColumns}
                      dataSource={anniversaries}
                      rowKey={(record) =>
                        record.employee_detail || Math.random()
                      }
                      loading={loading}
                      pagination={false}
                      locale={{
                        emptyText: "No work anniversaries this month",
                      }}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Celebrations;
