import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  employeeNotifications,
  patchNotifications,
} from "../../../../helpers/endpoints/api_endpoints";
import { ArrowRightCircle } from "react-feather";

const Activities = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await axiosInstance.get(employeeNotifications);
      setNotifications(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setLoading(false);
    }
  };

  const getNotificationLink = (type) => {
    switch (type) {
      case "course_added":
        return "/training-reports";
      case "course_rs":
        return "/training-approval";
      case "course_approval":
        return "/training-reports";
      case "course_publish":
        return "/employee-courses";
      case "certificate_rejected":
        return "/employee-courses";
      case "policy_rs":
        return "/policy-approval";
      case "feedback_added":
        return "/add-policies";
      case "policy_added":
        return "/add-policies";
      case "policy_emp":
        return "/employee-policy";
      case "policy_approval":
        return "/add-policies";
      case "appraisal":
        return "/appraisal-table";
      case "leaves_hr":
        return "/hr-leaves";
      case "leaves_sup":
        return "/approve-leaves";
      case "leaves_emp":
        return "/your-leaves";
      case "leaves_noti":
        return "/employee-leaves";
      case "technical_task":
        return "/technical-dashboard";
      default:
        return "/activities";
    }
  };

  const markAsRead = async (id) => {
    try {
      await axiosInstance.patch(patchNotifications(id), { is_read: true });
      const updatedNotifications = notifications.map((notification) =>
        notification.id === id
          ? { ...notification, is_read: true }
          : notification
      );
      setNotifications(updatedNotifications);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      render: (text, record) => (
        <Link
          to={getNotificationLink(record.type)}
          onClick={() => !record.is_read && markAsRead(record.id)}
        >
          <h6 style={{ color: record.is_read ? "#6c757d" : "#FF902F" }}>
            {text}
          </h6>
        </Link>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => (
        <div
          style={{
            fontSize: 11,
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            color: "black",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "time",
      render: (text) => new Date(text).toLocaleString(),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h3 className="page-title">Notifications</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/employee-dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Notifications</li>
              </ul>
            </div>
            <div className="col-md-6 text-end">
              <Link
                to="/employee-dashboard"
                className="btn btn-link"
                style={{ color: "#FF902F", textDecoration: "none" }}
              >
                <span className="me-1">Back to Dashboard</span>
                <ArrowRightCircle size={15} />
              </Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card flex-fill">
              <div className="card-body">
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={notifications}
                  rowKey={(record) => record.id}
                  loading={loading}
                  pagination={{ pageSize: 10 }}
                  rowClassName={(record) =>
                    record.is_read ? "bg-light" : "bg-light-warning"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activities;
