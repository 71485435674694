import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Table,
  Button,
  message,
  Typography,
  Space,
  Spin,
  Tag,
  Modal,
  Row,
  Col,
  Descriptions,
  Divider,
} from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  trackInitialization,
  predecessorElement,
} from "../../../../helpers/endpoints/api_endpoints";
import Header from "../../../layout/Header";
import OperationSideBar from "../../../layout/OperationsSidebar";
import { TrackProvider } from "../../../../contexts/TrackContext";

const { Title, Text } = Typography;

const SummaryPage = () => {
  const { trackId } = useParams();
  const [trackData, setTrackData] = useState(null);
  const [predecessorElements, setPredecessorElements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);

  useEffect(() => {
    fetchTrackData();
    fetchPredecessorElements();
  }, [trackId]);

  const fetchTrackData = async () => {
    try {
      const response = await axiosInstance.get(
        `${trackInitialization}${trackId}/`
      );
      setTrackData(response.data);
    } catch (error) {
      console.error("Error fetching track data:", error);
      message.error("Failed to fetch track data");
    }
  };

  const fetchPredecessorElements = async () => {
    try {
      const response = await axiosInstance.get(
        `${predecessorElement}?track=${trackId}`
      );
      setPredecessorElements(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching predecessor elements:", error);
      message.error("Failed to fetch predecessor elements");
      setLoading(false);
    }
  };

  const handleMarkAsComplete = async () => {
    try {
      await axiosInstance.patch(`${trackInitialization}${trackId}/`, {
        is_done: true,
      });
      message.success("Track marked as complete");
      fetchTrackData();
    } catch (error) {
      console.error("Error marking track as complete:", error);
      message.error("Failed to mark track as complete");
    }
  };

  const columns = [
    {
      title: "Assembly Line",
      dataIndex: "assembly_line",
      key: "assembly_line",
    },
    {
      title: "End Document Number",
      dataIndex: "end_document_number",
      key: "end_document_number",
    },
    {
      title: "Assignee",
      dataIndex: ["assignee_details", "employee_name"],
      key: "assignee",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "Approved" ? "green" : "orange"}>{status}</Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button onClick={() => showElementDetails(record)}>View</Button>
      ),
    },
  ];

  const showElementDetails = (element) => {
    setSelectedElement(element);
    setModalVisible(true);
  };

  if (loading) {
    return (
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  return (
    <>
      <Header />
      <TrackProvider>
        <OperationSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Track Summary</h3>
                </div>
                <div className="col-auto float-right ml-auto">
                  <Button
                    onClick={handleMarkAsComplete}
                    type="primary"
                    disabled={trackData?.is_done}
                  >
                    Mark as Complete
                  </Button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Card title="Track Details" className="card mb-0">
                  <Descriptions bordered column={2}>
                    <Descriptions.Item label="Name">
                      {trackData?.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Department">
                      {trackData?.department_details?.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Added By">
                      {trackData?.added_details?.employee_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                      {trackData?.is_done ? "Completed" : "In Progress"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Created At">
                      {trackData?.created_at}
                    </Descriptions.Item>
                    <Descriptions.Item label="Updated At">
                      {trackData?.updated_at}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <Card title="Predecessor Elements" className="card mb-0">
                  <Table
                    dataSource={predecessorElements}
                    columns={columns}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                  />
                </Card>
              </div>
            </div>
          </div>
        </div>
      </TrackProvider>

      <Modal
        title="Predecessor Element Details"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        width={1000}
        footer={null}
      >
        {selectedElement && (
          <>
            <Descriptions bordered column={2}>
              <Descriptions.Item label="End Document" span={2}>
                <Text strong>
                  {
                    selectedElement?.predecessor_details?.ed_field_details
                      ?.end_document_details?.end_name
                  }
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="End Field">
                <Text>
                  {
                    selectedElement?.predecessor_details?.ed_field_details
                      ?.field_name
                  }
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Predecessor Field">
                <Text>{selectedElement?.predecessor_details?.field_name}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Track">
                <Text>{selectedElement?.track_details?.name}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Department">
                <Text>
                  {selectedElement?.track_details?.department_details?.name}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="TC1 Status">
                <Text
                  type={selectedElement?.tc1_assignee ? "success" : "warning"}
                >
                  {selectedElement?.tc1_assignee ? "Approved" : "Pending"}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="TC2 Status">
                <Text
                  type={selectedElement?.tc2_assignee ? "success" : "warning"}
                >
                  {selectedElement?.tc2_assignee ? "Approved" : "Pending"}
                </Text>
              </Descriptions.Item>
            </Descriptions>

            <Divider orientation="left">Element Details</Divider>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Card size="small" title="Assembly Line">
                  <Text>{selectedElement?.assembly_line}</Text>
                </Card>
              </Col>
              <Col span={8}>
                <Card size="small" title="End Document Number">
                  <Text>{selectedElement?.end_document_number}</Text>
                </Card>
              </Col>
              <Col span={8}>
                <Card size="small" title="Field Code Number">
                  <Text>{selectedElement?.field_code_number}</Text>
                </Card>
              </Col>
              <Col span={12}>
                <Card size="small" title="Source Document">
                  <Text>{selectedElement?.source_document}</Text>
                </Card>
              </Col>
              <Col span={12}>
                <Card size="small" title="Action Sourcing">
                  <Text>{selectedElement?.action_sourcing}</Text>
                </Card>
              </Col>
              <Col span={24}>
                <Card size="small" title="Actions">
                  <Text>{selectedElement?.actions}</Text>
                </Card>
              </Col>
            </Row>

            <Divider orientation="left">Job Information</Divider>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Card size="small" title="Job Acceptance">
                  <Text>{selectedElement?.job_acceptance}</Text>
                </Card>
              </Col>
              <Col span={8}>
                <Card size="small" title="Job Initiation">
                  <Text>{selectedElement?.job_initiation}</Text>
                </Card>
              </Col>
              <Col span={8}>
                <Card size="small" title="Job Completion">
                  <Text>{selectedElement?.job_completion}</Text>
                </Card>
              </Col>
              <Col span={8}>
                <Card size="small" title="Total Execution Time">
                  <Text>{selectedElement?.total_execution_time}</Text>
                </Card>
              </Col>
              <Col span={8}>
                <Card size="small" title="Average Time">
                  <Text>{selectedElement?.average_time}</Text>
                </Card>
              </Col>
              <Col span={8}>
                <Card size="small" title="Database">
                  <Text>{selectedElement?.database}</Text>
                </Card>
              </Col>
            </Row>

            <Divider orientation="left">Additional Details</Divider>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Card size="small" title="Incoming Data">
                  <Text>{selectedElement?.incoming_data}</Text>
                </Card>
              </Col>
              <Col span={12}>
                <Card size="small" title="Outgoing Data">
                  <Text>{selectedElement?.outgoing_data}</Text>
                </Card>
              </Col>
              <Col span={8}>
                <Card size="small" title="Skills Required">
                  <Text>{selectedElement?.skills_required}</Text>
                </Card>
              </Col>
              <Col span={8}>
                <Card size="small" title="Triggers">
                  <Text>{selectedElement?.triggers}</Text>
                </Card>
              </Col>
              <Col span={8}>
                <Card size="small" title="Permissions">
                  <Text>{selectedElement?.permissions}</Text>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export default SummaryPage;
