import { Avatar_02 } from "../../../Routes/ImagePath";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Header from "../../layout/Header";
import AdminSideBar from "../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import { employeeList } from "../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../helpers/axios/axios";
import { Col, FormControl, InputGroup, Dropdown } from "react-bootstrap";

const AllEmployees = () => {
  const [employees, setEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const handleEditClick = (employeeId) => {
    window.location.href = `/employment-info/${employeeId}`;
  };

  useEffect(() => {
    // Fetch employee data from the API
    const fetchEmployees = async () => {
      try {
        const response = await axiosInstance.get(employeeList);
        console.log(response.data);
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    // Filter employees based on search term
    if (searchTerm) {
      const filtered = employees.filter((employee) =>
        employee.employee_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredEmployees(filtered);
    } else {
      setFilteredEmployees(employees);
    }
  }, [searchTerm, employees]);

  return (
    <div>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="All Employees"
            title="Dashboard"
            subtitle="All Employees"
            modal="#add_employee"
            name="Add Employee"
          />
          <Col md={3} className="mb-4 position-relative">
            <InputGroup>
              <FormControl
                placeholder="Search by Employee Name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
            <i
              className="fa fa-search position-absolute"
              style={{
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            ></i>
          </Col>

          <div className="row">
            {filteredEmployees.map((employee) => (
              <div
                className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                key={employee.id}
              >
                <div className="profile-widget">
                  <div className="profile-img">
                    <Link to="#" className="avatar">
                      {/* Use actual avatar if available, otherwise use a default avatar */}
                      <img
                        src={employee.avatar || Avatar_02}
                        alt={employee.employee_name}
                      />
                    </Link>
                  </div>
                  {/* Commented out the three dots action button */}

                  <Dropdown className="profile-action">
                    <Dropdown.Toggle
                      variant="link"
                      id={`dropdown-${employee.id}`}
                    >
                      <i className="material-icons">more_vert</i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          handleEditClick(employee.id);
                        }}
                      >
                        <i className="fa fa-pencil m-r-5" /> Edit
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                    <Link to="#">{employee.employee_name}</Link>
                  </h4>
                  <div className="small text-muted">
                    {employee.employment_details[0].designation_detail.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Assuming AllEmployeeAddPopup and DeleteModal are still needed */}
      {/* AllEmployeeAddPopup component */}
      {/* <AllEmployeeAddPopup /> */}

      {/* Delete Modal */}
      {/* <DeleteModal Name="Delete Employee" /> */}
    </div>
  );
};

export default AllEmployees;
