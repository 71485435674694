import React from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import Header from "../../../../layout/Header";
import { useLocation } from "react-router-dom";

import OperationSideBar from "../../../../layout/OperationsSidebar";
import { TrackProvider } from "../../../../../contexts/TrackContext";

const OperationsDashborad = () => {
  // const history = useHistory();
  const location = useLocation();
  console.log(location.pathname);
  const users = [
    {
      icon: "fa fa-cubes",
      id: 1,
      label: "Projects",
      number: 112,
    },
    {
      icon: "fa fa-usd",
      id: 2,
      label: "Clients",
      number: 44,
    },
    {
      icon: "fa-regular fa-gem",
      id: 3,
      label: "Tasks",
      number: 37,
    },
    {
      icon: "fa fa-user",
      id: 4,
      label: "Employees",
      number: 218,
    },
  ];
  return (
    <>
      <TrackProvider>
        <div>
          <Header />
          <OperationSideBar />
          <div className="main-wrapper">
            <div className="page-wrapper">
              <div className="content container-fluid">
                {/* Page Header */}
                <Breadcrumbs maintitle="Welcome Operations!" />
                {/* /Page Header */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="employee-alert-box">
                      <div className="alert alert-outline-success alert-dismissible fade show">
                        <div className="employee-alert-request flex items-center">
                          <i className="fas fa-exclamation-circle mr-2" />
                          Portal is on an updation and all the features will be
                          added later.
                        </div>
                        <button
                          type="button"
                          className="btn-close ml-auto"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        >
                          <i className="fas fa-xmark" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {Array.isArray(users) && users.length > 0 ? (
                    users.map((item, index) => (
                      <div
                        className="col-md-6 col-sm-6 col-lg-6 col-xl-3"
                        key={index}
                      >
                        <div className="card dash-widget">
                          <div className="card-body">
                            <span className={`dash-widget-icon ${item.icon}`} />
                            <div className="dash-widget-info">
                              <h3>{item.number}</h3>
                              <span>{item.label}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No data available</p>
                  )}
                </div>
                {/* /Charts */}
                {/* <Charts /> */}
                {/* /Charts */}
                {/* <Reports /> */}
                {/* <Statistics /> */}
                <div className="row">
                  {/* <InvoiceTable /> */}
                  {/* <PaymentTable /> */}
                </div>

                <div className="row">
                  {/* <ClientTable /> */}
                  {/* <RecentTable /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </TrackProvider>
    </>
  );
};

export default OperationsDashborad;
