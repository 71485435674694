import React, { useState, useEffect } from "react";
import Header from "../../../../layout/Header";
import AdminSideBar from "../../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import {
  Card,
  Row,
  Col,
  Typography,
  Spin,
  Statistic,
  Table,
  Avatar,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import { employeeDetails } from "../../../../../helpers/endpoints/api_endpoints";

const { Title } = Typography;

const AppraisalDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const [currentMonth, setCurrentMonth] = useState({ start: null, end: null });
  const [isHr, setIsHr] = useState(false);

  useEffect(() => {
    // Simulating API call with setTimeout
    setTimeout(() => {
      setDashboardData({
        totalEmployees: 150,
        averageProductivity: 85,
        completedTasks: 1250,
      });
      setIsLoading(false);
    }, 1000);

    // Set current month's start and end dates
    setCurrentMonth({
      start: moment().startOf("month").format("MMMM D, YYYY"),
      end: moment().endOf("month").format("MMMM D, YYYY"),
    });
  }, []);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then((response) => {
      setIsHr(
        response.data.employment_details[0].department_detail.name ===
          "Human Resource"
      );
    });
  }, []);

  const demoCostStatus = {
    totalRevenue: 0,
    totalCostTrack: 0,
    costStation: 0,
    actualCostBudget: 0,
    actualRevenueBudget: 0,
  };

  const columns = [
    {
      title: "Station",
      dataIndex: "station",
      key: "station",
    },
    {
      title: "Owner Name",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Time to complete",
      dataIndex: "timeToComplete",
      key: "timeToComplete",
      render: (time) => `${time}`,
    },
  ];

  const data = [
    {
      key: "1",
      station: "Appraisal Reports",
      ownerName: "Ashwini Swaroupa",
      task: "Assignee",
      timeToComplete: "56 minutes",
    },
  ];

  // Update Clock Status columns
  const clockStatusColumns = [
    {
      title: "Station",
      dataIndex: "station",
      key: "station",
      width: "30%",
    },
    {
      title: "Owner Name",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Station Clock",
      dataIndex: "stationClock",
      key: "stationClock",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
  ];

  // Add new Timeline Status columns
  const timelineStatusColumns = [
    {
      title: "Station",
      dataIndex: "station",
      key: "station",
      width: "30%",
    },
    {
      title: "Time to Accept",
      dataIndex: "timeToAccept",
      key: "timeToAccept",
    },
    {
      title: "Time to complete",
      dataIndex: "timeToComplete",
      key: "timeToComplete",
      render: (time) => `${time}`,
    },
    {
      title: "Average Time",
      dataIndex: "timeToComplete",
      key: "averageTime",
      render: (time) => `${time}`,
    },
  ];

  // Dummy data for the Clock Status table
  const clockStatusData = [
    {
      key: "1",
      station: "Appraisal Reports",
      ownerName: "Ashwini Swaroupa",
      stationClock: "0",
      timeToAccept: "0",
      task: "Assignee",
      timeToComplete: "56 minutes",
      start_date: currentMonth.start,
      end_date: currentMonth.start,
      date_range: `${currentMonth.start} - ${currentMonth.start}`,
    },
  ];

  return (
    <>
      {isHr ? (
        <>
          <Header />
          <AdminSideBar />
        </>
      ) : (
        <></>
      )}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Title level={2}>Appraisal Dashboard</Title>
          {isLoading ? (
            <Spin size="large" />
          ) : (
            <>
              <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
                <Col span={24}>
                  <Card>
                    <Title level={4}>
                      Current Month: {currentMonth.start} - {currentMonth.end}
                    </Title>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Card className="profile-card" style={{ height: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Avatar
                        size={64}
                        icon={<UserOutlined />}
                        style={{ marginBottom: "16px" }}
                      />
                      <Title level={4}>Ashwini Swaroupa</Title>
                      <p>SubTrack Owner</p>
                    </div>
                  </Card>
                </Col>
                <Col span={16}>
                  <Card title="Current Station" className="station-card">
                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      rowKey="key"
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                <Col span={24}>
                  <Card title="Clock Status" className="clock-status-card">
                    <Table
                      columns={clockStatusColumns}
                      dataSource={clockStatusData}
                      pagination={false}
                      rowKey="key"
                      expandable={{
                        expandedRowRender: (record) => (
                          <p style={{ margin: 0 }}>
                            Date Range: {record.date_range}
                          </p>
                        ),
                      }}
                      scroll={{ x: "max-content" }}
                      style={{ overflowX: "auto" }}
                    />
                  </Card>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                <Col span={18}>
                  <Card
                    title="Timeline Status"
                    className="timeline-status-card"
                    style={{ height: "100%" }}
                  >
                    <Table
                      columns={timelineStatusColumns}
                      dataSource={clockStatusData}
                      pagination={false}
                      rowKey="key"
                      scroll={{ x: "max-content" }}
                      style={{ overflowX: "auto" }}
                    />
                  </Card>
                </Col>

                <Col span={6}>
                  <Card
                    title="Cost Status"
                    className="cost-status-card"
                    style={{ height: "100%" }}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Statistic
                          title="Total Revenue"
                          value={demoCostStatus.totalRevenue}
                          prefix="AED"
                          valueStyle={{ color: "#3f8600", fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic
                          title="Total Cost/Track"
                          value={demoCostStatus.totalCostTrack}
                          prefix="AED"
                          valueStyle={{ color: "#cf1322", fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic
                          title="Cost/Station"
                          value={demoCostStatus.costStation}
                          prefix="AED"
                          valueStyle={{ color: "#1890ff", fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic
                          title="Actual Cost/Budget"
                          value={demoCostStatus.actualCostBudget}
                          suffix="%"
                          valueStyle={{
                            color:
                              demoCostStatus.actualCostBudget > 50
                                ? "#cf1322"
                                : "#3f8600",
                            fontSize: "14px",
                          }}
                        />
                      </Col>
                      <Col span={24}>
                        <Statistic
                          title="Actual Revenue/Budget"
                          value={demoCostStatus.actualRevenueBudget}
                          suffix="%"
                          valueStyle={{
                            color:
                              demoCostStatus.actualRevenueBudget > 50
                                ? "#3f8600"
                                : "#cf1322",
                            fontSize: "14px",
                          }}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AppraisalDashboard;
