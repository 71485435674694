export const SidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "HR Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/hr-dashboard",
        icon: "la la-dashcube",
      },

      {
        menuValue: "Employees",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-user",
        subMenus: [
          {
            menuValue: "Employee Data Dashboard",
            route: "/employee-data-dashboard",
          },
          {
            menuValue: "Add Employee",
            route: "/employees",
          },
          {
            menuValue: "All Employee",
            route: "/employeeDetails",
          },
        ],
      },
      {
        menuValue: "ZII",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-list",
        subMenus: [
          {
            menuValue: "ZII Dashboard",
            route: "/zii-dashboard",
          },
          {
            menuValue: "ZII Topics",
            route: "/zii-topic",
          },
          {
            menuValue: "ZII Reports",
            route: "/zii-reports",
          },
        ],
      },
      {
        menuValue: "Training",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-graduation-cap",
        subMenus: [
          {
            menuValue: "Training Dashboard",
            route: "/training-dashboard",
          },
          {
            menuValue: "Approval List",
            route: "/training-reports",
          },
          {
            menuValue: "Certificate Verification",
            route: "/training-verification",
          },
        ],
      },
      {
        menuValue: "Policies",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-file-pdf-o",
        subMenus: [
          {
            menuValue: "Policy Dashboard",
            route: "/policy-dashboard",
          },
          {
            menuValue: "Add New Policy",
            route: "/add-policies",
          },
        ],
      },
      {
        menuValue: "Appraisal Reports",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-star-o",
        subMenus: [
          {
            menuValue: "Appraisal Dashboard",
            route: "/appraisal-dashboard",
          },
          {
            menuValue: "Department Appraisal Report",
            route: "/hr-app-report",
          },
          {
            menuValue: "Monthly Report",
            route: "/appraisal-report",
          },
        ],
      },

      {
        menuValue: "Holidays",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-calendar-minus-o",
        subMenus: [
          {
            menuValue: "Holiday Dashboard",
            route: "/holiday-dashboard",
          },
          {
            menuValue: "Add Holidays",
            route: "/holidays-calendar",
          },
        ],
      },
      {
        menuValue: "Celebrations",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-birthday-cake",
        subMenus: [
          {
            menuValue: "Celebrations Dashboard",
            route: "/celebrations-dashboard",
          },
          {
            menuValue: "Celebrations",
            route: "/celebrations",
          },
        ],
      },
      {
        menuValue: "Leaves",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-calendar",
        subMenus: [
          {
            menuValue: "Leave Dashboard",
            route: "/leave-dashboard",
          },
          {
            menuValue: "Approve Leaves",
            route: "/hr-leaves",
          },
        ],
      },
    ],
  },
];
