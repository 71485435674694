import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { axiosInstance } from "../helpers/axios/axios";
import { trackInitialisation } from "../helpers/endpoints/api_endpoints";

const TrackInitContext = createContext();

export const useTrackInitContext = () => {
  const context = useContext(TrackInitContext);
  if (!context) {
    throw new Error(
      "useTrackInitContext must be used within a TrackInitProvider"
    );
  }
  return context;
};

export const TrackInitProvider = ({ children }) => {
  const [tracks, setTracks] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const updateTracks = useCallback((newTracks) => {
    setTracks(newTracks);
    setError(null);
  }, []); // Empty dependency array since it doesn't depend on any external values

  const fetchTracks = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(trackInitialisation);
      setTracks(response.data);
      setError(null);
    } catch (error) {
      console.error("Error fetching tracks:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []); // Empty dependency array since it doesn't depend on any external values

  const value = useMemo(
    () => ({
      tracks,
      updateTracks,
      fetchTracks,
      error,
      isLoading,
    }),
    [tracks, fetchTracks, updateTracks, error, isLoading]
  );

  return (
    <TrackInitContext.Provider value={value}>
      {children}
    </TrackInitContext.Provider>
  );
};
