import React, { useState, useEffect } from "react";
import {
  Modal,
  Spin,
  Typography,
  Descriptions,
  Row,
  Col,
  Card,
  Tag,
  Divider,
  Empty,
} from "antd";
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { predecessorElement } from "../../../../helpers/endpoints/api_endpoints";

const { Title, Text } = Typography;

const PDElementModal = ({ visible, onClose, predecessorId }) => {
  const [loading, setLoading] = useState(false);
  const [elementData, setElementData] = useState(null);

  useEffect(() => {
    if (visible && predecessorId) {
      setLoading(true);
      axiosInstance
        .get(`${predecessorElement}?predecessor=${predecessorId}`)
        .then((response) => {
          setElementData(response.data.length > 0 ? response.data[0] : null);
          console.log("Data", response.data);
        })
        .catch((error) => {
          console.error("Error fetching predecessor element:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [visible, predecessorId]);

  const isFullyApproved = elementData?.status === "Approved";
  const tc1Approved = elementData?.tc1_assignee !== null;
  const tc2Approved = elementData?.tc2_assignee !== null;

  return (
    <Modal
      title={
        <Title level={3}>
          Predecessor Element Details
          {elementData && (
            <Tag
              color={isFullyApproved ? "green" : "orange"}
              style={{ marginLeft: 16 }}
            >
              {isFullyApproved ? (
                <>
                  <CheckCircleOutlined /> Fully Approved
                </>
              ) : (
                <>
                  <ClockCircleOutlined /> {elementData.status}
                </>
              )}
            </Tag>
          )}
        </Title>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
      className="pd-element-modal"
    >
      {loading ? (
        <Spin size="large" />
      ) : elementData ? (
        <>
          <Descriptions bordered column={2}>
            <Descriptions.Item label="End Document" span={2}>
              <Text strong>
                {
                  elementData.predecessor_details?.ed_field_details
                    ?.end_document_details?.end_name
                }
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="End Field">
              <Text>
                {elementData.predecessor_details?.ed_field_details?.field_name}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="Predecessor Field">
              <Text>{elementData.predecessor_details?.field_name}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Track">
              <Text>{elementData.track_details?.name}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Department">
              <Text>{elementData.track_details?.department_details?.name}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="TC1 Status">
              <Text type={tc1Approved ? "success" : "warning"}>
                {tc1Approved ? "Approved" : "Pending"}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="TC2 Status">
              <Text type={tc2Approved ? "success" : "warning"}>
                {tc2Approved ? "Approved" : "Pending"}
              </Text>
            </Descriptions.Item>
          </Descriptions>

          <Divider orientation="left">Element Details</Divider>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Card size="small" title="Assembly Line">
                <Text>{elementData.assembly_line}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="End Document Number">
                <Text>{elementData.end_document_number}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Field Code Number">
                <Text>{elementData.field_code_number}</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small" title="Source Document">
                <Text>{elementData.source_document}</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small" title="Action Sourcing">
                <Text>{elementData.action_sourcing}</Text>
              </Card>
            </Col>
            <Col span={24}>
              <Card size="small" title="Actions">
                <Text>{elementData.actions}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Compliance">
                <Text>{elementData?.compliance}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Cost">
                <Text>{elementData?.cost}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Revenue">
                <Text>{elementData?.revenue}</Text>
              </Card>
            </Col>
          </Row>

          <Divider orientation="left">Job Details</Divider>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Card size="small" title="Job Acceptance">
                <Text>{elementData.job_acceptance}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Job Initiation">
                <Text>{elementData.job_initiation}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Job Completion">
                <Text>{elementData.job_completion}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Total Execution Time">
                <Text>{elementData.total_execution_time}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Average Time">
                <Text>{elementData.average_time}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Database">
                <Text>{elementData.database}</Text>
              </Card>
            </Col>
          </Row>

          <Divider orientation="left">Additional Details</Divider>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card size="small" title="Accuracy Check">
                <Text>{elementData?.accuracy_check}</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small" title="Notification">
                <Text>{elementData?.notification}</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small" title="Outgoing Data">
                <Text>{elementData?.outgoing_data}</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small" title="Incoming Data">
                <Text>{elementData?.incoming_data}</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small" title="Data Field Format">
                <Text>{elementData?.data_field_format}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Meta Data">
                <Text>{elementData?.metadata}</Text>
              </Card>
            </Col>
          </Row>

          <Divider orientation="left">Input and Output Information</Divider>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card size="small" title="Input Data">
                <Text>{elementData?.input_data}</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small" title="Output Data">
                <Text>{elementData?.output_data}</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small" title="Input Source">
                <Text>{elementData?.input_source}</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small" title="Output Source">
                <Text>{elementData?.output_source}</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small" title="Input Field Number">
                <Text>{elementData?.input_field_number}</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small" title="Output Field Number">
                <Text>{elementData?.output_field_number}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Document Status">
                <Text>{elementData?.document_status}</Text>
              </Card>
            </Col>
          </Row>

          <Divider orientation="left">Requirements and Permissions</Divider>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Card size="small" title="Skills Required">
                <Text>{elementData?.skills_required}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Triggers">
                <Text>{elementData?.triggers}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Permissions">
                <Text>{elementData?.permissions}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Responsible Department">
                <Text>{elementData?.responsible_department}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="Branch">
                <Text>{elementData?.branch}</Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" title="System Action">
                <Text>{elementData?.system_action}</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small" title="Action Code">
                <Text>{elementData?.action_code}</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small" title="Events">
                <Text>{elementData?.events}</Text>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <Empty
          description={<span>No predecessor element data available</span>}
        />
      )}
    </Modal>
  );
};

export default PDElementModal;
