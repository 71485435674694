import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Tag,
  Tooltip,
  Spin,
  Input,
  Select,
} from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addLeaves,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import { FaListAlt, FaExternalLinkAlt } from "react-icons/fa";

const ApproveLeaves = () => {
  const [leaves, setLeaves] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeeID, setEmployeeID] = useState(null);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [statusFilter, setStatusFilter] = useState(null);

  const fetchLeaves = useCallback(async () => {
    try {
      setLoading(true);
      const params = new URLSearchParams({
        page: 1,
        page_size: 10,
        supervisor: employeeID,
      });

      if (statusFilter) {
        params.append("status", statusFilter);
      }

      const response = await axiosInstance.get(
        `${addLeaves}?${params.toString()}`
      );
      setLeaves(response.data.results);
    } catch (error) {
      console.error("Error fetching leaves:", error);
      toast.error("Failed to fetch leaves");
    } finally {
      setLoading(false);
    }
  }, [employeeID, statusFilter]);

  useEffect(() => {
    axiosInstance.get(employeeDetails).then(
      (response) => {
        setEmployeeID(response.data.employment_details[0].id);
      },
      (error) => {
        console.error("Error fetching employee details:", error);
        toast.error("Failed to fetch employee details");
      }
    );
  }, []);

  useEffect(() => {
    if (employeeID) {
      fetchLeaves();
    }
  }, [employeeID, fetchLeaves]);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Employee",
      dataIndex: ["user_details", "employee_name"],
      key: "employee_name",
      render: (_, record) => record?.user_details?.employee_name || "-",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (text) =>
        text ? (text.length > 20 ? text.slice(0, 20) + "..." : text) : "-",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : "-"),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : "-"),
    },
    {
      title: "Total Days",
      dataIndex: "total_days",
      key: "total_days",
      render: (days) => days || "-",
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        let color = "warning";
        if (record.status === "Approved") {
          color = "success";
        } else if (record.status === "Rejected") {
          color = "error";
        }
        return <Tag color={color}>{record.status || "Pending"}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="d-flex gap-2">
          <Button type="primary" onClick={() => handleViewLeave(record)}>
            View
          </Button>
          {record?.tasks?.length > 0 && (
            <Tooltip title={`${record.tasks.length} Task(s) Available`}>
              <Button
                type="default"
                icon={<FaListAlt />}
                onClick={() => handleViewTasks(record.tasks)}
              />
            </Tooltip>
          )}
          {record?.document && (
            <Tooltip title="View Document">
              <Button
                type="default"
                icon={<FaExternalLinkAlt />}
                onClick={() => window.open(record.document, "_blank")}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const handleViewLeave = (leave) => {
    console.log("Selected leave:", leave);
    setSelectedLeave(leave);
    setIsModalOpen(true);
  };

  const handleViewTasks = (tasks) => {
    console.log("Selected tasks:", tasks);
    setSelectedTasks(tasks);
    setShowTaskModal(true);
  };

  const handleApproveLeave = async () => {
    try {
      setLoading(true);
      await axiosInstance.patch(`${addLeaves}${selectedLeave.id}/`, {
        status: "Approved",
        approved_by: employeeID,
        approved_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      toast.success("Leave approved successfully");
      fetchLeaves();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error approving leave:", error);
      toast.error("Failed to approve leave");
    } finally {
      setLoading(false);
    }
  };

  const handleRejectLeave = async () => {
    try {
      setLoading(true);
      await axiosInstance.patch(`${addLeaves}${selectedLeave.id}/`, {
        status: "Rejected",
        approved_by: employeeID,
        approved_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      toast.success("Leave rejected successfully");
      fetchLeaves();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error rejecting leave:", error);
      toast.error("Failed to reject leave");
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = (value) => {
    setStatusFilter(value);
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="content-page-header d-flex justify-content-between align-items-center">
            <h5>Approve Leaves</h5>
            <Select
              placeholder="Filter by Status"
              allowClear
              style={{ width: 200 }}
              onChange={handleStatusChange}
              value={statusFilter}
            >
              <Select.Option value="Pending">Pending</Select.Option>
              <Select.Option value="Approved">Approved</Select.Option>
              <Select.Option value="Rejected">Rejected</Select.Option>
            </Select>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <Spin spinning={loading} tip="Loading leaves...">
                  <Table
                    dataSource={leaves}
                    columns={columns}
                    rowKey="id"
                    pagination={{
                      pageSize: 10,
                      showTotal: (total) => `Total ${total} items`,
                    }}
                    bordered
                    scroll={{ x: 1000 }}
                    style={{ overflowX: "auto" }}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title="Task Details"
          open={showTaskModal}
          onCancel={() => setShowTaskModal(false)}
          footer={[
            <Button key="close" onClick={() => setShowTaskModal(false)}>
              Close
            </Button>,
          ]}
          width={800}
          bodyStyle={{ maxHeight: "80vh", overflow: "auto" }}
        >
          <Table
            dataSource={selectedTasks}
            columns={[
              {
                title: "Task Description",
                dataIndex: "task_description",
                key: "task_description",
                width: "30%",
                fixed: "left",
                render: (desc) => desc || "-",
              },
              {
                title: "Links",
                key: "links",
                width: "40%",
                render: (_, record) => (
                  <div className="d-flex flex-column gap-2">
                    {record?.document_link && (
                      <a
                        href={record.document_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center gap-1"
                        style={{ color: "#1890ff" }}
                      >
                        <FaExternalLinkAlt size={14} />
                        <span>Document Link</span>
                      </a>
                    )}
                    {record?.platform_link && (
                      <a
                        href={record.platform_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center gap-1"
                        style={{ color: "#1890ff" }}
                      >
                        <FaExternalLinkAlt size={14} />
                        <span>Platform Link</span>
                      </a>
                    )}
                  </div>
                ),
              },
              {
                title: "Credentials",
                key: "credentials",
                width: "30%",
                render: (_, record) =>
                  record?.credentials ? (
                    <div className="d-flex flex-column gap-1">
                      <div>
                        <strong>User ID:</strong>{" "}
                        {record.credentials.user_id || "-"}
                      </div>
                      <div>
                        <strong>Password:</strong>{" "}
                        {record.credentials.password || "-"}
                      </div>
                    </div>
                  ) : (
                    "No credentials"
                  ),
              },
            ]}
            pagination={false}
            rowKey={(record) => record?.task_description || Math.random()}
            scroll={{ y: 300, x: 800 }}
            style={{
              maxHeight: "400px",
              overflow: "auto",
            }}
          />
        </Modal>

        <Modal
          title="Leave Details"
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={[
            <Button
              key="reject"
              type="primary"
              danger
              onClick={handleRejectLeave}
              disabled={selectedLeave?.status !== "Pending"}
            >
              Reject Leave
            </Button>,
            <Button
              key="approve"
              type="primary"
              onClick={handleApproveLeave}
              disabled={selectedLeave?.status !== "Pending"}
            >
              Approve Leave
            </Button>,
            <Button key="close" onClick={() => setIsModalOpen(false)}>
              Close
            </Button>,
          ]}
          width={800}
          bodyStyle={{ maxHeight: "80vh", overflow: "auto" }}
        >
          {selectedLeave && (
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Employee">
                    <Input
                      value={selectedLeave?.user_details?.employee_name}
                      disabled
                      style={{ backgroundColor: "#f5f5f5" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Status">
                    <Tag
                      color={
                        selectedLeave.status === "Approved"
                          ? "success"
                          : selectedLeave.status === "Rejected"
                          ? "error"
                          : "warning"
                      }
                    >
                      {selectedLeave.status}
                    </Tag>
                  </Form.Item>
                </Col>
              </Row>

              {(selectedLeave.status === "Approved" ||
                selectedLeave.status === "Rejected") && (
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label={`${selectedLeave.status} By`}>
                      <Input
                        value={
                          selectedLeave?.approver_details?.employee_name || "-"
                        }
                        disabled
                        style={{ backgroundColor: "#f5f5f5" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={`${selectedLeave.status} Date`}>
                      <Input
                        value={
                          selectedLeave?.approved_at
                            ? moment(selectedLeave.approved_at).format(
                                "DD-MM-YYYY HH:mm"
                              )
                            : "-"
                        }
                        disabled
                        style={{ backgroundColor: "#f5f5f5" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Start Date">
                    <Input
                      value={
                        selectedLeave?.start_date
                          ? moment(selectedLeave.start_date).format(
                              "DD-MM-YYYY"
                            )
                          : "-"
                      }
                      disabled
                      style={{ backgroundColor: "#f5f5f5" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="End Date">
                    <Input
                      value={
                        selectedLeave?.end_date
                          ? moment(selectedLeave.end_date).format("DD-MM-YYYY")
                          : "-"
                      }
                      disabled
                      style={{ backgroundColor: "#f5f5f5" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Total Days">
                    <Input
                      value={selectedLeave?.total_days || "-"}
                      disabled
                      style={{ backgroundColor: "#f5f5f5" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Applied On">
                    <Input
                      value={
                        selectedLeave?.created_at
                          ? moment(selectedLeave.created_at).format(
                              "DD-MM-YYYY"
                            )
                          : "-"
                      }
                      disabled
                      style={{ backgroundColor: "#f5f5f5" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {selectedLeave?.leave_details?.length > 0 && (
                <div className="mt-4">
                  <h5>Daily Leave Details</h5>
                  <Table
                    dataSource={selectedLeave.leave_details}
                    columns={[
                      {
                        title: "Date",
                        dataIndex: "date",
                        key: "date",
                        render: (date) =>
                          date ? moment(date).format("DD-MM-YYYY") : "-",
                      },
                      {
                        title: "Leave Type",
                        dataIndex: "leave_specification",
                        key: "leave_specification",
                        render: (spec) => spec || "-",
                      },
                    ]}
                    pagination={false}
                    rowKey="date"
                    size="small"
                    scroll={{ y: 200 }}
                    style={{ maxHeight: "300px" }}
                  />
                </div>
              )}
            </Form>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default ApproveLeaves;
