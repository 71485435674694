import React, { useState, useEffect } from "react";
import Header from "../../../layout/Header";
import TechnicalSideBar from "../../../layout/TechnicalSideBar";
import {
  Card,
  Row,
  Col,
  Typography,
  Spin,
  Statistic,
  Table,
  Avatar,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { TrackInitProvider } from "../../../../contexts/TrackInitContext";

const { Title } = Typography;

const TechDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    // Simulating API call with setTimeout
    setTimeout(() => {
      setDashboardData({
        totalEmployees: 150,
        averageProductivity: 85,
        completedTasks: 1250,
      });
      setIsLoading(false);
    }, 1000);
  }, []);

  const demoCostStatus = {
    totalRevenue: 0,
    totalCostTrack: 0,
    costStation: 0,
    actualCostBudget: 0,
    actualRevenueBudget: 0,
  };

  const columns = [
    {
      title: "Station",
      dataIndex: "station",
      key: "station",
    },
    {
      title: "Owner Name",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Time to complete",
      dataIndex: "timeToComplete",
      key: "timeToComplete",
      render: (time) => `${time} `,
    },
  ];

  const data = [
    {
      key: "2",
      station: "Security Check",
      ownerName: "Bonface Mwicwiri",
      task: "Assignee",
      timeToComplete: "2 hours 48 minutes",
    },
    {
      key: "3",
      station: "Going Live",
      ownerName: "Srujith Sasi",
      task: "Assignee",
      timeToComplete: "34 minutes",
    },
  ];

  const clockStatusColumns = [
    {
      title: "Station",
      dataIndex: "station",
      key: "station",
      width: "30%",
    },
    {
      title: "Owner Name",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Station\nClock",
      dataIndex: "station_clock",
      key: "station_clock",
      width: "100px",
    },
    {
      title: "Time to Accept",
      dataIndex: "timeToAccept",
      key: "timeToAccept",
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      render: (time) => {
        if (time === "-") return "-";
        const [date, timeStr, period] = time.split(" ");
        return (
          <div style={{ whiteSpace: "pre-line" }}>
            {date}
            <br />
            {timeStr} {period}
          </div>
        );
      },
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      render: (time) => {
        if (time === "-") return "-";
        const [date, timeStr, period] = time.split(" ");
        return (
          <div style={{ whiteSpace: "pre-line" }}>
            {date}
            <br />
            {timeStr} {period}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Time to complete",
      dataIndex: "timeToComplete",
      key: "timeToComplete",
      render: (time) => {
        if (time === "-") return "-";
        const [hours, minutes] = time.split(",");
        return (
          <div style={{ whiteSpace: "pre-line" }}>
            {hours}
            <br />
            {minutes}
          </div>
        );
      },
    },
    {
      title: "Average Time",
      dataIndex: "timeToComplete",
      key: "averageTime",
      render: (time) => {
        if (time === "-") return "-";
        const [hours, minutes] = time.split(",");
        return (
          <div style={{ whiteSpace: "pre-line" }}>
            {hours}
            <br />
            {minutes}
          </div>
        );
      },
    },
  ];

  // Dummy data for the Clock Status table
  const clockStatusData = [
    {
      key: "1",
      station: "EC Acceptance",
      ownerName: "Srujith",
      station_clock: "105",
      timeToAccept: "0",
      task: "Assignee",
      timeToComplete: "7 hours, 53 minutes",
      start_time: "15/10/2024 08:37 AM",
      end_time: "15/10/2024 04:30 PM",
      status: "Done",
    },
    {
      key: "2",
      station: "Wire frames creation",
      ownerName: "Manashvini",
      station_clock: "435",
      timeToAccept: "0",
      task: "Assignee",
      timeToComplete: "09 hours, 42 minutes",
      start_time: "16/10/2024 08:48 AM",
      end_time: "16/10/2024 06:30 PM",
      status: "Done",
    },
    {
      key: "3",
      station: "To convert the wireframe to UI",
      station_clock: "565",
      ownerName: "Anik",
      timeToAccept: "0",
      task: "Assignee",
      timeToComplete: "09 hours, 55 minutes",
      start_time: "17/10/2024 08:35 AM",
      end_time: "17/10/2024 06:30 PM",
      status: "Done",
    },
    {
      key: "4",
      station: "Write the code for the Backend",
      station_clock: "404",
      ownerName: "Kevin",
      timeToAccept: "0",
      task: "Assignee",
      timeToComplete: "09 hours, 48 minutes",
      start_time: "17/10/2024 08:42 AM",
      end_time: "17/10/2024 06:30 PM",
      status: "Done",
    },
    {
      key: "5",
      station: "Write code for the Mobile App development",
      station_clock: "365",
      ownerName: "Fredrick",
      timeToAccept: "0",
      task: "Assignee",
      timeToComplete: "-",
      start_time: "-",
      end_time: "-",
      status: "-",
    },
    {
      key: "6",
      station: "Integration code between to connect backend and UI",
      station_clock: "565",
      ownerName: "Ritwik",
      timeToAccept: "0",
      task: "Assignee",
      timeToComplete: "4 hours, 52 minutes",
      start_time: "18/10/2024 09:38 AM",
      end_time: "18/10/2024 02:30 PM",
      status: "Done",
    },
    {
      key: "7",
      station: "React front end testing",
      station_clock: "539",
      ownerName: "Lucky",
      timeToAccept: "0",
      task: "Assignee",
      timeToComplete: "1 hour, 8 minutes",
      start_time: "18/10/2024 02:32 PM",
      end_time: "18/10/2024 03:40 PM",
      status: "Done",
    },
    {
      key: "8",
      station: "GCP deployment",
      station_clock: "165",
      ownerName: "Srujith",
      timeToAccept: "0",
      task: "Assignee",
      timeToComplete: "57 minutes",
      start_time: "18/10/2024 03:50 PM",
      end_time: "18/10/2024 04:47 PM",
      status: "Done",
    },
    {
      key: "9",
      station: "Testing the Data flow from the track",
      station_clock: "539",
      ownerName: "Bonface",
      timeToAccept: "0",
      task: "Assignee",
      timeToComplete: "1 hour, 3 minutes",
      start_time: "18/10/2024 04:52 PM",
      end_time: "18/10/2024 06:05 PM",
      status: "Done",
    },
    {
      key: "10",
      station: "Security check",
      station_clock: "539",
      ownerName: "Bonface",
      timeToAccept: "0",
      task: "Assignee",
      timeToComplete: "2 hours, 48 minutes",
      start_time: "18/10/2024 04:58 PM",
      end_time: "18/10/2024 07:46 PM",
      status: "Done",
    },
    {
      key: "11",
      station: "Going live",
      station_clock: "270",
      ownerName: "Srujith",
      timeToAccept: "0",
      task: "Assignee",
      timeToComplete: "34 minutes",
      start_time: "18/10/2024 07:48 PM",
      end_time: "18/10/2024 08:22 PM",
      status: "Done",
    },
  ];

  return (
    <>
      <Header />
      <TrackInitProvider>
        <TechnicalSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <Title level={2}>DCDF - Summary Track</Title>
            {isLoading ? (
              <Spin size="large" />
            ) : (
              <>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Card className="profile-card" style={{ height: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <Avatar
                          size={64}
                          icon={<UserOutlined />}
                          style={{ marginBottom: "16px" }}
                        />
                        <Title level={4}>Manuraj Madayi</Title>
                        <p>Owner</p>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#8c8c8c",
                            marginBottom: "4px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Start Date:
                          </span>{" "}
                          15/10/2024
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#8c8c8c",
                            marginBottom: "4px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>End Date:</span>{" "}
                          18/10/2024
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#8c8c8c",
                            marginBottom: "4px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Department:
                          </span>{" "}
                          Operations
                        </p>
                      </div>
                    </Card>
                  </Col>
                  <Col span={16}>
                    <Card title="Current Station" className="station-card">
                      <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="key"
                      />
                    </Card>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                  <Col span={24}>
                    <Card title="Clock Status" className="clock-status-card">
                      <div
                        style={{
                          overflowX: "auto",
                          overflowY: "auto",
                          maxHeight: "400px",
                        }}
                      >
                        <Table
                          columns={clockStatusColumns}
                          dataSource={clockStatusData}
                          pagination={false}
                          rowKey="key"
                          style={{ overflowX: "auto" }}
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                  <Col span={24}>
                    <Card title="Cost Status" className="cost-status-card">
                      <Row gutter={[16, 16]}>
                        <Col span={6}>
                          <Statistic
                            title="Total Revenue"
                            value={demoCostStatus.totalRevenue}
                            prefix="AED"
                            valueStyle={{ color: "#3f8600" }}
                          />
                        </Col>
                        <Col span={6}>
                          <Statistic
                            title="Total Cost/Track"
                            value={demoCostStatus.totalCostTrack}
                            prefix="AED"
                            valueStyle={{ color: "#cf1322" }}
                          />
                        </Col>
                        <Col span={6}>
                          <Statistic
                            title="Cost/Station"
                            value={demoCostStatus.costStation}
                            prefix="AED"
                            valueStyle={{ color: "#1890ff" }}
                          />
                        </Col>
                        <Col span={6}>
                          <Statistic
                            title="Actual Cost/Budget"
                            value={demoCostStatus.actualCostBudget}
                            suffix="%"
                            valueStyle={{
                              color:
                                demoCostStatus.actualCostBudget > 50
                                  ? "#cf1322"
                                  : "#3f8600",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                        <Col span={24}>
                          <Statistic
                            title="Actual Revenue/Budget"
                            value={demoCostStatus.actualRevenueBudget}
                            suffix="%"
                            valueStyle={{
                              color:
                                demoCostStatus.actualRevenueBudget > 50
                                  ? "#3f8600"
                                  : "#cf1322",
                            }}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </TrackInitProvider>
    </>
  );
};

export default TechDashboard;
