import React, { useState } from "react";
import { Table, Button } from "antd";
import { Modal } from "react-bootstrap";
import { TrackProvider } from "../../../../contexts/TrackContext";
import OperationSideBar from "../../../layout/OperationsSidebar";
import PredecessorFieldsModal from "./PredecessorFieldModal";

const PredecessorField = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const dataSource = [
    {
      id: 1,
      trackName: "GACL",
      endDocumentNames: "Checklist",
      endDocumentFields: "Track Name\nEnd Document Names\nEnd Document Fields",
      predecessorFields: "Track Name in the DB",
      predecessorElements: "End Document name in PD elements form",
    },
  ];

  const columns = [
    {
      title: "Track Name",
      dataIndex: "trackName",
      key: "trackName",
    },
    {
      title: "End Document Names",
      dataIndex: "endDocumentNames",
      key: "endDocumentNames",
    },
    {
      title: "End Document Fields",
      dataIndex: "endDocumentFields",
      key: "endDocumentFields",
    },
    {
      title: "Predecessor Fields",
      dataIndex: "predecessorFields",
      key: "predecessorFields",
    },
    {
      title: "Predecessor Elements",
      dataIndex: "predecessorElements",
      key: "predecessorElements",
    },
    {
      title: "View",
      key: "view",
      render: (text, record) => (
        <Button type="primary" onClick={() => handleView(record)}>
          View/Edit
        </Button>
      ),
    },
  ];

  const handleView = (record) => {
    setSelectedRecord(record); // Store the selected record
    setShowModal(true); // Open the modal
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedRecord(null); // Clear the selected record when closing
  };

  return (
    <TrackProvider>
      <OperationSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          {/* Page Header */}
          <div className="page-header">
            <h3 className="page-title">Predecessor Fields & Elements</h3>
          </div>

          {/* Main Content */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  dataSource={dataSource}
                  columns={columns}
                  rowKey="id"
                  pagination={false} // Disable pagination for now
                />
              </div>
            </div>
          </div>

          {/* Modal with PredecessorFieldsModal inside */}
          <Modal show={showModal} onHide={handleModalClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Predecessor Fields & Elements</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Passing the selected record to the PredecessorFieldsModal */}
              <PredecessorFieldsModal selectedRecord={selectedRecord} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </TrackProvider>
  );
};

export default PredecessorField;
