import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { avatar19, clockin } from "../../../../../Routes/ImagePath";
import { ArrowRightCircle, Gift, Award } from "react-feather";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  employeeDetails,
  employeeNotifications,
  patchNotifications,
  holidays,
  celebrations,
} from "../../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../../../../../store/actions/accountsActions";
import { debounce } from "lodash"; // Make sure to import lodash
import moment from "moment";

const EmployeeDashboard = () => {
  const [employeeDetail, setEmployeeDetails] = useState();
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [isPageReloaded, setIsPageReloaded] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const [upcomingHoliday, setUpcomingHoliday] = useState(null);
  const [todaysBirthdays, setTodaysBirthdays] = useState([]);
  const [todaysAnniversaries, setTodaysAnniversaries] = useState([]);
  const [activeTab, setActiveTab] = useState("birthdays");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const debouncedHandleLogout = useCallback(
    debounce(() => {
      if (loggingOut) return; // Prevent multiple logout attempts
      setLoggingOut(true);
      dispatch({ type: LOGOUT });
      localStorage.removeItem("accessToken");
      navigate("/", { replace: true });
      setLoggingOut(false);
    }, 300),
    [dispatch, navigate, loggingOut]
  );

  useEffect(() => {
    const isFirstTime = sessionStorage.getItem("isFirstTimeOnDashboard");

    if (!isFirstTime) {
      sessionStorage.setItem("isFirstTimeOnDashboard", "true");
      sessionStorage.setItem("showToast", "true");
      setIsPageReloaded(true);
    } else {
      setIsPageReloaded(true);
    }
  }, []);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeDetails(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    axiosInstance.get(`${employeeNotifications}?is_read=false`).then(
      (response) => {
        const top20UnreadNotifications = response.data.slice(0, 20);
        setUnreadNotifications(top20UnreadNotifications);
      },
      (error) => {
        console.log(error);
      }
    );

    axiosInstance.get(`${employeeNotifications}?is_read=true`).then(
      (response) => {
        const top20ReadNotifications = response.data.slice(0, 20);
        setReadNotifications(top20ReadNotifications);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    if (isPageReloaded && sessionStorage.getItem("showToast") === "true") {
      toast.success("Log in Successful");
      sessionStorage.removeItem("showToast");
    }
  }, [isPageReloaded]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      debouncedHandleLogout();
    }
  }, [debouncedHandleLogout]);

  useEffect(() => {
    const fetchUpcomingHoliday = async () => {
      try {
        const response = await axiosInstance.get(holidays);
        if (response.data) {
          const today = moment();
          const nextHoliday = response.data
            .filter((holiday) => moment(holiday.date).isAfter(today))
            .sort((a, b) => moment(a.date).diff(moment(b.date)))[0];

          if (nextHoliday) {
            setUpcomingHoliday({
              title: nextHoliday.holiday_name,
              date: moment(nextHoliday.date),
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUpcomingHoliday();
  }, []);

  useEffect(() => {
    const fetchCelebrations = async () => {
      const today = moment();
      const day = today.format("D");
      const month = today.format("M");

      try {
        const [birthdaysRes, anniversariesRes] = await Promise.all([
          axiosInstance.get(
            `${celebrations}?type=birthday&day=${day}&month=${month}`
          ),
          axiosInstance.get(
            `${celebrations}?type=anniversary&day=${day}&month=${month}`
          ),
        ]);

        setTodaysBirthdays(birthdaysRes.data);
        setTodaysAnniversaries(anniversariesRes.data);
      } catch (error) {
        console.error("Error fetching celebrations:", error);
      }
    };

    fetchCelebrations();
  }, []);

  useEffect(() => {
    if (todaysBirthdays.length === 0 && todaysAnniversaries.length > 0) {
      setActiveTab("anniversaries");
    }
  }, [todaysBirthdays.length, todaysAnniversaries.length]);

  const markAsRead = (id) => {
    axiosInstance
      .patch(patchNotifications(id), { is_read: true })
      .then((response) => {
        const updatedUnreadNotifications = unreadNotifications.filter(
          (notification) => notification.id !== id
        );
        const movedNotification = unreadNotifications.find(
          (notification) => notification.id === id
        );
        setUnreadNotifications(updatedUnreadNotifications);
        setReadNotifications([movedNotification, ...readNotifications]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          <div className="container-fluid">
            {/* Alert Message */}
            <div className="row">
              <div className="col-md-12">
                <div className="employee-alert-box">
                  <div className="alert alert-outline-success alert-dismissible fade show">
                    <div className="employee-alert-request">
                      <i className="far fa-circle-question" />
                      Portal is on an updation and all the features will be
                      added later!!!
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="fas fa-xmark" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Main Content */}
            <div className="row">
              {/* Left Column - Welcome & Holiday Cards */}
              <div className="col-xxl-4 col-lg-4">
                {/* Welcome Card */}
                <div className="card employee-welcome-card mb-3">
                  <div className="card-body">
                    <div className="welcome-info">
                      <div className="welcome-content">
                        <h4>Welcome,{employeeDetail?.employee_name || "NA"}</h4>
                        <p>
                          You have <span>0 meetings</span> today,
                        </p>
                      </div>
                      <div className="welcome-img">
                        <img src={avatar19} className="img-fluid" alt="User" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Holiday Card */}
                <div className="card info-card">
                  <div className="card-body">
                    <h4>Upcoming Holidays</h4>
                    <div className="holiday-details">
                      <div className="holiday-calendar">
                        <div className="holiday-calendar-icon">
                          <img
                            src="https://smarthr.dreamstechnologies.com/html/template/assets/img/icons/holiday-calendar.svg"
                            alt="Holiday Calendar"
                          />
                        </div>
                        <div className="holiday-calendar-content">
                          {upcomingHoliday ? (
                            <>
                              <h6>{upcomingHoliday.title}</h6>
                              <p>
                                {upcomingHoliday.date.format("ddd DD MMM YYYY")}
                              </p>
                            </>
                          ) : (
                            <p>No upcoming holidays</p>
                          )}
                        </div>
                      </div>
                      <div className="holiday-btn">
                        <Link to="/employee-holidays" className="btn">
                          View All{" "}
                          <ArrowRightCircle size={15} className="ms-1" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Middle Column - Celebrations Card */}
              <div className="col-xxl-4 col-lg-4">
                <div
                  className="card employee-month-card"
                  style={{
                    backgroundColor: "#fff5f7",
                    border: "1px solid #ffccd5",
                  }}
                >
                  <div className="card-body">
                    <h4 className="mb-3">Today's Celebrations</h4>

                    <div className="notification-tab">
                      <ul className="nav nav-tabs flex-nowrap overflow-auto small-screen-tabs">
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              activeTab === "birthdays" ? "active" : ""
                            }`}
                            data-bs-toggle="tab"
                            href="#birthdays_tab"
                            onClick={() => setActiveTab("birthdays")}
                          >
                            <Gift className="me-1" size={14} />
                            <span className="tab-text">Birthdays</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              activeTab === "anniversaries" ? "active" : ""
                            }`}
                            data-bs-toggle="tab"
                            href="#anniversaries_tab"
                            onClick={() => setActiveTab("anniversaries")}
                            style={{
                              position: "relative",
                              // Add a subtle glow effect if there are both birthdays and anniversaries
                              ...(todaysBirthdays.length > 0 &&
                                todaysAnniversaries.length > 0 && {
                                  backgroundColor: "#fff3cd",
                                  color: "#ffc107",
                                  fontWeight: "500",
                                }),
                            }}
                          >
                            <Award className="me-1" size={14} />
                            <span className="tab-text">Anniversaries</span>
                            {/* Add a small indicator dot if there are both types of celebrations */}
                            {todaysBirthdays.length > 0 &&
                              todaysAnniversaries.length > 0 && (
                                <span
                                  style={{
                                    position: "absolute",
                                    top: -5,
                                    right: -5,
                                    width: 8,
                                    height: 8,
                                    borderRadius: "50%",
                                    backgroundColor: "#ffc107",
                                  }}
                                />
                              )}
                          </a>
                        </li>
                      </ul>

                      <div className="tab-content mt-3">
                        {/* Birthdays Tab */}
                        <div
                          className={`tab-pane ${
                            activeTab === "birthdays" ? "active" : ""
                          }`}
                          id="birthdays_tab"
                        >
                          <div
                            className="celebration-list"
                            style={{
                              maxHeight: "200px",
                              overflowY: "auto",
                              paddingRight: "10px",
                              // Add responsive styles
                              width: "100%",
                              minWidth: "250px", // Minimum width for readability
                            }}
                          >
                            {todaysBirthdays.length > 0 ? (
                              todaysBirthdays.map((person, index) => (
                                <div
                                  key={index}
                                  className="employee-month-details mb-3"
                                >
                                  <div className="d-flex align-items-center">
                                    <div
                                      className="avatar-circle"
                                      style={{
                                        width: "45px",
                                        height: "45px",
                                        backgroundColor: "#ffe0e6",
                                        color: "#ff4d6d",
                                        borderRadius: "50%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: "18px",
                                        fontWeight: "600",
                                        marginRight: "15px",
                                      }}
                                    >
                                      {person.employee_name.charAt(0)}
                                    </div>
                                    <div>
                                      <h6 className="mb-1">
                                        {person.employee_name}
                                      </h6>
                                      <p
                                        className="mb-0"
                                        style={{
                                          fontSize: "12px",
                                          color: "#666",
                                        }}
                                      >
                                        Wish them a happy birthday! 🎉
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p className="text-muted mb-0">
                                No birthdays today
                              </p>
                            )}
                          </div>
                        </div>

                        {/* Anniversaries Tab */}
                        <div
                          className={`tab-pane ${
                            activeTab === "anniversaries" ? "active" : ""
                          }`}
                          id="anniversaries_tab"
                        >
                          <div
                            className="celebration-list"
                            style={{
                              maxHeight:
                                todaysAnniversaries.length > 3
                                  ? "200px"
                                  : "auto",
                              overflowY:
                                todaysAnniversaries.length > 3
                                  ? "auto"
                                  : "visible",
                              paddingRight:
                                todaysAnniversaries.length > 3 ? "10px" : "0",
                            }}
                          >
                            {todaysAnniversaries.length > 0 ? (
                              todaysAnniversaries.map((person, index) => {
                                // Calculate years of service
                                const yearsOfService = moment().diff(
                                  moment(person.effective_date),
                                  "years"
                                );

                                return (
                                  <div
                                    key={index}
                                    className="employee-month-details mb-3"
                                  >
                                    <div className="d-flex align-items-center">
                                      <div
                                        className="avatar-circle"
                                        style={{
                                          width: "45px",
                                          height: "45px",
                                          backgroundColor: "#fff3cd",
                                          color: "#ffc107",
                                          borderRadius: "50%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          fontSize: "18px",
                                          fontWeight: "600",
                                          marginRight: "15px",
                                        }}
                                      >
                                        {person.employee_details.employee_name.charAt(
                                          0
                                        )}
                                      </div>
                                      <div>
                                        <h6 className="mb-1">
                                          {
                                            person.employee_details
                                              .employee_name
                                          }
                                        </h6>
                                        <p
                                          className="mb-0"
                                          style={{
                                            fontSize: "12px",
                                            color: "#666",
                                          }}
                                        >
                                          {yearsOfService} years at company
                                        </p>
                                        <small className="text-muted">
                                          {
                                            person.employee_details
                                              .employment_details[0]
                                              .designation_detail.name
                                          }{" "}
                                          -{" "}
                                          {
                                            person.employee_details
                                              .employment_details[0]
                                              .department_detail.name
                                          }
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-muted mb-0">
                                No work anniversaries today
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Right Column - Notifications */}
              <div className="col-xxl-4 col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <div className="statistic-header">
                      <h4>Important</h4>
                      <div className="important-notification">
                        <Link to="/activities">
                          <span className="me-1">View All</span>
                          <ArrowRightCircle size={15} />
                        </Link>
                      </div>
                    </div>
                    <div className="notification-tab">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#unread_notification_tab"
                          >
                            <i className="la la-bell" /> Unread
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#read_notification_tab"
                          >
                            <i className="la la-check-circle" /> Read
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className="tab-pane active"
                          id="unread_notification_tab"
                        >
                          <div
                            className="employee-noti-content"
                            style={{ maxHeight: "400px", overflowY: "auto" }}
                          >
                            <ul className="employee-notification-list">
                              {unreadNotifications.length > 0 ? (
                                unreadNotifications.map((notification) => (
                                  <NotificationItem
                                    key={notification.id}
                                    notification={notification}
                                    markAsRead={markAsRead}
                                  />
                                ))
                              ) : (
                                <li className="employee-notification-grid">
                                  <div className="employee-notification-content">
                                    <h6>No unread notifications!</h6>
                                  </div>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                        <div className="tab-pane" id="read_notification_tab">
                          <div
                            className="employee-noti-content"
                            style={{ maxHeight: "400px", overflowY: "auto" }}
                          >
                            <ul className="employee-notification-list">
                              {readNotifications.length > 0 ? (
                                readNotifications.map((notification) => (
                                  <NotificationItem
                                    key={notification.id}
                                    notification={notification}
                                    markAsRead={() => {}}
                                    isRead={true}
                                  />
                                ))
                              ) : (
                                <li className="employee-notification-grid">
                                  <div className="employee-notification-content">
                                    <h6>No read notifications!</h6>
                                  </div>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NotificationItem = ({ notification, markAsRead, isRead = false }) => {
  const getLinkTo = (type) => {
    switch (type) {
      case "course_added":
        return "/training-reports";
      case "course_rs":
        return "/training-approval";
      case "course_approval":
        return "/training-reports";
      case "course_publish":
        return "/employee-courses";
      case "certificate_rejected":
        return "/employee-courses";
      case "policy_rs":
        return "/policy-approval";
      case "feedback_added":
        return "/add-policies";
      case "policy_added":
        return "/add-policies";
      case "policy_approval":
        return "/add-policies";
      case "policy_emp":
        return "/employee-policy";
      case "appraisal":
        return "/appraisal-table";
      case "leaves_hr":
        return "/hr-leaves";
      case "leaves_sup":
        return "/approve-leaves";
      case "leaves_emp":
        return "/your-leaves";
      case "leaves_noti":
        return "/employee-leaves";
      case "technical_task":
        return "/technical-dashboard";
      case "holiday_added":
        return "/employee-holidays";
      default:
        return "/activities";
    }
  };

  return (
    <li
      className="employee-notification-grid"
      style={{
        backgroundColor: isRead ? "#e0f7fa" : "#fff3e0",
        padding: "10px",
        margin: "5px 0",
        borderRadius: "5px",
      }}
      onClick={() => !isRead && markAsRead(notification.id)}
    >
      <div className="employee-notification-icon">
        <Link to={getLinkTo(notification.type)}>
          <span className="badge-soft-danger rounded-circle">HR</span>
        </Link>
      </div>
      <div className="employee-notification-content">
        <Link to={getLinkTo(notification.type)}>
          <h6
            style={{
              color: isRead ? "#6c757d" : "#FF902F",
            }}
          >
            {notification.title}
          </h6>
          <div
            style={{
              fontSize: 11,
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              color: "black",
            }}
          >
            {notification.description}
          </div>
        </Link>
        <ul className="nav">
          <li>{new Date(notification.time).toLocaleString()}</li>
        </ul>
      </div>
    </li>
  );
};

export default EmployeeDashboard;
