import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  basicUserDetails,
  documentList,
} from "../../../../../helpers/endpoints/api_endpoints";

const PaySlipReportTable = () => {
  const [documentLists, setDocumentLists] = useState([]);
  const [users, setUsers] = useState({});

  useEffect(() => {
    axiosInstance
      .get(`${documentList}`)
      .then((response) => {
        console.log(response.data);
        setDocumentLists(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!documentLists) return;
      const userIds = documentLists.map((doc) => doc.uploaded_by);
      const uniqueUserIds = [...new Set(userIds)];

      uniqueUserIds.forEach(async (userId) => {
        try {
          const response = await axiosInstance.get(basicUserDetails(userId));
          setUsers((prevUsers) => ({
            ...prevUsers,
            [userId]: response.data,
          }));
          console.log(response.data);
        } catch (error) {
          console.log(error);
        }
      });
    };

    fetchUsers();
  }, [documentLists]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "File Name",
      dataIndex: "file_name",
      render: (text, record) => (
        <div className="table-avatar">
          {/* <Link to="/profile" className="avatar">
            <img alt="" src={record.image} />
          </Link>
          <Link to="/profile">
            {text} <span>{record.role}</span>
          </Link> */}
          {text}
        </div>
      ),
    },
    {
      title: "Finalized Name",
      dataIndex: "finalized_name",
      render: (text, record) => (
        <div className="table-avatar">
          {record?.finalized_name ? (
            record?.finalized_name
          ) : (
            <>no name available </>
          )}
        </div>
      ),
    },

    {
      title: "Is Finalize",
      dataIndex: "is_finalized",
      render: (record) => (
        <div className="table-avatar">
          {record?.is_finalized ? "Yes" : "No"}
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: (date) => <div className="table-avatar">{date.slice(0, 10)}</div>,
    },
    {
      title: "Created By",
      dataIndex: "uploaded_by",
      key: "created_by",
      render: (uploadedBy) => {
        const user = users[uploadedBy];
        return user ? (
          <div className="table-avatar">{user?.employee_name}</div>
        ) : (
          <div className="table-avatar">Loading...</div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Link
            to={record.uploaded_file}
            className="btn btn-sm btn-primary ms-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            View File
          </Link>
        </>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={documentLists}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </div>
  );
};

export default PaySlipReportTable;
