import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  Tabs,
  Card,
  Button,
  Row,
  Col,
  Statistic,
  Spin,
  Modal,
  Upload,
  Input,
} from "antd";
import { toast } from "react-toastify";
import moment from "moment";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  trackJobassignments,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { UploadOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { TextArea } = Input;

const TechnicalTrackManagement = () => {
  const { trackId } = useParams();
  const [activeTab, setActiveTab] = useState("1");
  const [isLoading, setIsLoading] = useState(true);
  const [assignedJobs, setAssignedJobs] = useState([]);
  const [costStatus, setCostStatus] = useState({
    totalRevenue: 0,
    totalCostTrack: 0,
    costStation: 0,
    actualCostBudget: 0,
    actualRevenueBudget: 0,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [comment, setComment] = useState("");
  const [fileList, setFileList] = useState([]);
  const [employeeDesignation, setEmployeeDesignation] = useState(null);
  const [employeeID, setEmployeeId] = useState(null);

  const fetchEmployeeDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${employeeDetails}`);
      setEmployeeDesignation(
        response.data.employment_details[0].designation_detail.name
      );
      setEmployeeId(response.data.employment_details[0].id);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Failed to fetch employee details");
    }
  }, []);

  const fetchAssignedJobs = useCallback(async () => {
    try {
      let url = `${trackJobassignments}?track=${trackId}`;
      if (employeeDesignation !== "Group Leader" && employeeID) {
        url += `&employee=${employeeID}`;
      }
      const response = await axiosInstance.get(url);
      setAssignedJobs(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching assigned jobs:", error);
      toast.error("Failed to fetch assigned jobs");
      setAssignedJobs([]);
    } finally {
      setIsLoading(false);
    }
  }, [trackId, employeeDesignation, employeeID]);

  useEffect(() => {
    fetchEmployeeDetails();
  }, [fetchEmployeeDetails]);

  useEffect(() => {
    if (employeeDesignation !== null && employeeID !== null) {
      fetchAssignedJobs();
    }
  }, [fetchAssignedJobs, employeeDesignation, employeeID]);

  const currentStationColumns = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: ["assignee_details", "assignee_details", "employee_name"],
        key: "name",
        render: (text, record) =>
          record.assignee_details?.assignee_details?.employee_name || "N/A",
      },
      {
        title: "Station",
        dataIndex: ["assignee_details", "subtrack_details", "name"],
        key: "station",
        render: (text, record) =>
          record.assignee_details?.subtrack_details?.name || "N/A",
      },
      {
        title: "Status",
        key: "status",
        render: (_, record) => {
          if (record.is_complete) return "Completed";
          if (record.is_accepted) return "In Progress";
          return "Pending";
        },
      },
    ],
    []
  );

  const clockStatusColumns = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: ["assignee_details", "assignee_details", "employee_name"],
        key: "name",
        width: "20%",
        render: (text, record) =>
          record.assignee_details?.assignee_details?.employee_name || "N/A",
      },
      {
        title: "Station",
        dataIndex: ["assignee_details", "subtrack_details", "name"],
        key: "station",
        width: "15%",
        render: (text, record) =>
          record.assignee_details?.subtrack_details?.name || "N/A",
      },
      {
        title: "Start Time",
        dataIndex: "accepted_at",
        key: "start_time",
        width: "15%",
        render: (text) => {
          if (!text) {
            return "Not Started";
          }
          return moment(text).isValid()
            ? moment(text).format("DD-MM-YYYY HH:mm:ss")
            : "Not Started";
        },
      },
      {
        title: "End Time",
        dataIndex: "completed_at",
        key: "end_time",
        width: "15%",
        render: (text, record) => {
          if (!record.is_complete) {
            return "Not Ended";
          }
          const endTime = record.updated_at;
          return moment(endTime).isValid()
            ? moment(endTime).format("DD-MM-YYYY HH:mm:ss")
            : "Not Ended";
        },
      },
      {
        title: "Time to Accept Job",
        key: "time_to_accept",
        width: "15%",
        render: (_, record) => {
          const created = moment(record.created_at);
          const start = moment(record.accepted_at);
          const diffMinutes = start.diff(created, "minutes");

          if (isNaN(diffMinutes)) {
            return "-";
          } else if (diffMinutes < 60) {
            return `${diffMinutes} mins`;
          } else {
            const diffHours = Math.floor(diffMinutes / 60);
            return `${diffHours} hrs`;
          }
        },
      },
      {
        title: "Total Time",
        key: "total_time",
        width: "10%",
        render: (_, record) => {
          if (!record.is_complete) return "-";

          const start = moment(record.accepted_at);
          const end = moment(record.updated_at);
          const diffMinutes = end.diff(start, "minutes");

          if (isNaN(diffMinutes)) {
            return "-";
          } else if (diffMinutes < 60) {
            return `${diffMinutes} mins`;
          } else {
            const diffHours = Math.floor(diffMinutes / 60);
            const remainingMinutes = diffMinutes % 60;
            if (remainingMinutes === 0) {
              return `${diffHours} hrs`;
            } else {
              return `${diffHours} hrs ${remainingMinutes} mins`;
            }
          }
        },
      },
      {
        title: "Average Time",
        key: "average_time",
        width: "10%",
        render: () => "-",
      },
    ],
    []
  );

  const handleAcceptJob = async (record) => {
    try {
      await axiosInstance.patch(`${trackJobassignments}${record.id}/`, {
        is_accepted: true,
      });
      toast.success("Job accepted successfully!");
      fetchAssignedJobs();
    } catch (error) {
      console.error("Error accepting job:", error);
      toast.error("Failed to accept job");
    }
  };

  const handleCompleteJob = async () => {
    try {
      const formData = new FormData();
      if (fileList[0]) {
        formData.append("file", fileList[0].originFileObj);
      }
      formData.append("comment", comment);
      formData.append("is_complete", true);

      await axiosInstance.patch(
        `${trackJobassignments}${selectedJob.id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsModalVisible(false);
      setComment("");
      setFileList([]);
      toast.success("Job completed successfully!");
      fetchAssignedJobs();
    } catch (error) {
      console.error("Error completing job:", error);
      toast.error("Failed to complete job");
    }
  };

  const showJobDetails = (record) => {
    Modal.info({
      title: "Job Details",
      content: (
        <div>
          <p>Comment: {record.comment || "No comment"}</p>
          {record.file && (
            <p>
              <Button
                type="primary"
                onClick={() => window.open(record.file, "_blank")}
              >
                View File
              </Button>
            </p>
          )}
        </div>
      ),
    });
  };

  const columns = useMemo(
    () => [
      {
        title: "Assignee",
        dataIndex: ["assignee_details", "assignee_details", "employee_name"],
        key: "assignee",
        render: (text, record) =>
          record.assignee_details?.assignee_details?.employee_name || "N/A",
      },

      {
        title: "Station",
        dataIndex: ["assignee_details", "subtrack_details", "name"],
        key: "station",
        render: (text, record) =>
          record.assignee_details?.subtrack_details?.name || "N/A",
      },
      {
        title: "Status",
        key: "status",
        render: (_, record) => {
          if (record.is_complete) return "Completed";
          if (record.is_accepted) return "In Progress";
          return "Pending";
        },
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        render: (text) => moment(text).format("DD-MM-YYYY"),
      },
      {
        title: "Action",
        key: "action",
        render: (_, record) => {
          if (record.is_complete) {
            return (
              <Button type="primary" onClick={() => showJobDetails(record)}>
                View Details
              </Button>
            );
          }
          if (!record.is_accepted) {
            return (
              <Button type="primary" onClick={() => handleAcceptJob(record)}>
                Accept
              </Button>
            );
          }
          return (
            <Button
              type="primary"
              onClick={() => {
                setSelectedJob(record);
                setIsModalVisible(true);
              }}
            >
              Complete Job
            </Button>
          );
        },
      },
    ],
    []
  );

  return (
    <Card>
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Dashboard" key="1">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title="Current Station" className="station-card">
                <Table
                  columns={currentStationColumns}
                  dataSource={assignedJobs}
                  pagination={false}
                  scroll={{ y: 240 }}
                  rowKey="id"
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card title="Clock Status" className="clock-status-card">
                <Table
                  columns={clockStatusColumns}
                  dataSource={assignedJobs}
                  pagination={false}
                  scroll={{ y: 240 }}
                  rowKey="id"
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card
                title="Cost Status"
                className="cost-status-card"
                bodyStyle={{ padding: "12px" }}
              >
                <Row gutter={[8, 8]}>
                  {/* First Row */}
                  <Col span={8}>
                    <Statistic
                      title="Total Revenue"
                      value={costStatus.totalRevenue}
                      prefix="AED "
                      valueStyle={{ color: "#3f8600", fontSize: "16px" }}
                    />
                  </Col>
                  <Col span={8}>
                    <Statistic
                      title="Total Cost/Track"
                      value={costStatus.totalCostTrack}
                      prefix="AED "
                      valueStyle={{ color: "#cf1322", fontSize: "16px" }}
                    />
                  </Col>
                  <Col span={8}>
                    <Statistic
                      title="Cost/Station"
                      value={costStatus.costStation}
                      prefix="AED "
                      valueStyle={{ color: "#1890ff", fontSize: "16px" }}
                    />
                  </Col>

                  {/* Second Row */}
                  <Col span={12}>
                    <Statistic
                      title="Actual Cost/Budget"
                      value={costStatus.actualCostBudget}
                      suffix="%"
                      valueStyle={{
                        color:
                          costStatus.actualCostBudget > 50
                            ? "#cf1322"
                            : "#3f8600",
                        fontSize: "16px",
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Actual Revenue/Budget"
                      value={costStatus.actualRevenueBudget}
                      suffix="%"
                      valueStyle={{
                        color:
                          costStatus.actualRevenueBudget > 50
                            ? "#3f8600"
                            : "#cf1322",
                        fontSize: "16px",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Assigned Jobs" key="2">
          {isLoading ? (
            <div className="loading-spinner">
              <Spin size="large" />
            </div>
          ) : (
            <Card className="assigned-jobs-card">
              <Table
                columns={columns}
                dataSource={assignedJobs}
                rowKey={(record) => record.id}
                pagination={{ pageSize: 10 }}
                scroll={{ x: true }}
              />
            </Card>
          )}
        </TabPane>
      </Tabs>

      <Modal
        title="Complete Job"
        open={isModalVisible}
        onOk={handleCompleteJob}
        onCancel={() => {
          setIsModalVisible(false);
          setComment("");
          setFileList([]);
        }}
      >
        <Upload
          fileList={fileList}
          onChange={({ fileList }) => setFileList(fileList)}
          beforeUpload={() => false}
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Upload File</Button>
        </Upload>
        <TextArea
          placeholder="Add your comment here..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          style={{ marginTop: 16 }}
          rows={4}
        />
      </Modal>
    </Card>
  );
};

export default TechnicalTrackManagement;
