import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Button,
  Spin,
  Typography,
  List,
  Select,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  assignedJob,
  endDocument,
  endDocumentField,
  predecessorElement,
  predecessorField,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import Header from "../../../layout/Header";
import OperationSideBar from "../../../layout/OperationsSidebar";
import { TrackProvider } from "../../../../contexts/TrackContext";

const { Text } = Typography;
const { Option } = Select;

const AssignedJobs = () => {
  const { trackId, station: initialStation, jobId } = useParams();
  const [form] = Form.useForm();
  const [selectedJob, setSelectedJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [endNames, setEndNames] = useState([]);
  const [selectedStation, setSelectedStation] = useState(initialStation);
  const [employeeId, setEmployeeId] = useState(null);

  const endpointMap = useMemo(
    () => ({
      "End Document": endDocument,
      "ED Field": endDocumentField,
      "PD Field": predecessorField,
      "PD Element": predecessorElement,
    }),
    []
  );

  const fetchEmployeeDetails = async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);
      setEmployeeId(response.data.employment_details[0].id);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Failed to fetch employee details");
    }
  };

  // useEffect to fetch employee details
  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  const fetchEndNames = useCallback(
    async (job) => {
      if (!job || !job.station) return;

      const endpoint = endpointMap[job.station]; // depends on endpointMap
      if (!endpoint) return;

      try {
        const response = await axiosInstance.get(
          `${endpoint}?track=${job.track}`
        );
        if (response.data.length > 0) {
          const fetchedEndNames = response.data.map((item) => ({
            name: item.end_name,
            id: item.id,
          }));
          setEndNames(fetchedEndNames);
        }
      } catch (error) {
        console.error("Error fetching end names:", error);
        toast.error("Failed to fetch end names");
      }
    },
    [endpointMap]
  );

  const fetchJobs = useCallback(async () => {
    if (employeeId) {
      try {
        const response = await axiosInstance.get(
          `${assignedJob}?track=${trackId}&assignee=${employeeId}&station=${encodeURIComponent(
            selectedStation
          )}`
        );

        if (response.data.length > 0) {
          const job = response.data[0];
          setSelectedJob(job);
          await fetchEndNames(job); // depends on fetchEndNames
        } else {
          setSelectedJob(null);
          setEndNames([]);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
        toast.error("Failed to fetch jobs");
      } finally {
        setLoading(false);
      }
    }
  }, [trackId, selectedStation, fetchEndNames, employeeId]); // added fetchEndNames as a dependency

  const onFinish = useCallback(
    async (values) => {
      setSubmitting(true);
      const toastId = toast.loading("Processing...");

      const endpoint = endpointMap[selectedStation]; // depends on endpointMap
      if (!endpoint) {
        toast.error("Invalid station");
        setSubmitting(false);
        toast.dismiss(toastId);
        return;
      }

      try {
        const payload = {
          track: selectedJob.track,
          assignee: selectedJob.assignee,
          end_names: values.new_end_names,
        };
        await axiosInstance.post(endpoint, payload);
        if (jobId) {
          await axiosInstance.patch(`${assignedJob}${jobId}/`, {
            status: "Completed",
          });
        }
        toast.success("Job completed successfully");
        fetchJobs();
      } catch (error) {
        console.error("Error updating job:", error);
        toast.error("Failed to update job");
      } finally {
        setSubmitting(false);
        toast.dismiss(toastId);
      }
    },
    [selectedJob, selectedStation, endpointMap, fetchJobs, jobId] // added endpointMap and fetchJobs as dependencies
  );
  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  const handleStationChange = (value) => {
    setSelectedStation(value);
    setLoading(true);
  };

  useEffect(() => {
    if (selectedStation) {
      fetchJobs();
    }
  }, [selectedStation, fetchJobs]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <TrackProvider>
        <OperationSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid position-relative">
            <Card
              title={`Assigned Jobs for Track: ${
                selectedJob?.track_details?.name || "N/A"
              }`}
            >
              {/* <Select
                disabled
                style={{ width: 200, marginBottom: 20 }}
                value={selectedStation}
                onChange={handleStationChange}
              >
                {Object.keys(endpointMap).map((station) => (
                  <Option key={station} value={station}>
                    {station}
                  </Option>
                ))}
              </Select> */}

              {selectedJob ? (
                <>
                  <p>
                    Department:{" "}
                    {selectedJob.track_details?.department_details?.name}
                  </p>
                  <p>Assignee: {selectedJob.assignee_details?.employee_name}</p>
                  <p>
                    Station: <Text strong>{selectedStation}</Text>
                  </p>

                  <div style={{ marginBottom: "20px" }}>
                    <Text strong>Existing End Names:</Text>
                    <List
                      dataSource={endNames}
                      renderItem={(item) => <List.Item>{item.name}</List.Item>}
                    />
                  </div>

                  <Form form={form} onFinish={onFinish} layout="vertical">
                    <Form.List name="new_end_names">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              required={false}
                              key={field.key}
                              label={index === 0 ? "Add New End Names" : ""}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={["onChange", "onBlur"]}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message:
                                      "Please input end name or delete this field.",
                                  },
                                ]}
                                noStyle
                              >
                                <Input
                                  style={{ width: "60%" }}
                                  placeholder="Enter End Name"
                                />
                              </Form.Item>
                              <Button
                                type="link"
                                onClick={() => remove(field.name)}
                              >
                                Delete
                              </Button>
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                            >
                              Add End Name
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={submitting}
                      >
                        Submit New End Names
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              ) : (
                <p>No job assigned for the selected station.</p>
              )}
            </Card>
          </div>
        </div>
      </TrackProvider>
    </>
  );
};

export default AssignedJobs;
