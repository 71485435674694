import React from "react";
import Header from "../../../layout/Header";
import TechnicalSideBar from "../../../layout/TechnicalSideBar";
import { TrackInitProvider } from "../../../../contexts/TrackInitContext";
import TechTaskDashboardContent from "./TechTaskDashboardContent";

const TechTaskDashboard = () => {
  return (
    <>
      <Header />
      <TrackInitProvider>
        <TechnicalSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid position-relative">
            <TechTaskDashboardContent />
          </div>
        </div>
      </TrackInitProvider>
    </>
  );
};

export default TechTaskDashboard;
