import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Table,
  Tabs,
  Card,
  Spin,
  Button,
  Row,
  Col,
  Select,
  Tag,
  Statistic,
} from "antd";
import { toast } from "react-toastify";
import moment from "moment";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  trackInitialization,
  assignedJob,
  employeeDetails,
  summaryField,
} from "../../../../helpers/endpoints/api_endpoints";
import PDElementModal from "./PDElementModal";
import "./TrackManagement.css";

const { TabPane } = Tabs;
const { Option } = Select;

const TrackManagementTask = ({ onTrackComplete }) => {
  const { trackId } = useParams();
  const navigate = useNavigate();
  const [assignedJobs, setAssignedJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [trackDetails, setTrackDetails] = useState(null);
  const [employeeDesignation, setEmployeeDesignation] = useState(null);
  const [employeeID, setEmployeeId] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [selectedEndName, setSelectedEndName] = useState(null);
  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const [selectedEndField, setSelectedEndField] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPredecessorId, setSelectedPredecessorId] = useState(null);
  const [trackCompleted, setTrackCompleted] = useState(false);

  const fetchEmployeeDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${employeeDetails}`);
      setEmployeeDesignation(
        response.data.employment_details[0].designation_detail.name
      );
      setEmployeeId(response.data.employment_details[0].id);
      console.log(
        response.data.employment_details[0].designation_detail.name,
        response.data.employment_details[0].id
      );
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Failed to fetch employee details");
    }
  }, [setEmployeeDesignation, setEmployeeId]);

  const fetchAssignedJobs = useCallback(async () => {
    try {
      let url = `${assignedJob}?track=${trackId}`;
      if (employeeDesignation !== "Team Leader" && employeeID) {
        url += `&assignee=${employeeID}`;
      }
      const response = await axiosInstance.get(url);
      setAssignedJobs(Array.isArray(response.data) ? response.data : []);
      console.log("Data", response.data);
    } catch (error) {
      console.error("Error fetching assigned jobs:", error);
      toast.error("Failed to fetch assigned jobs");
      setAssignedJobs([]);
    } finally {
      setIsLoading(false);
    }
  }, [trackId, employeeDesignation, employeeID]);

  const fetchTrackDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `${trackInitialization}${trackId}/`
      );
      setTrackDetails(response.data);
    } catch (error) {
      console.error("Error fetching track details:", error);
      toast.error("Failed to fetch track details");
    }
  }, [trackId]);

  const fetchSummaryData = useCallback(async () => {
    setIsSummaryLoading(true);
    try {
      const response = await axiosInstance.get(`${summaryField}${trackId}/`);
      setSummaryData(response.data);
      if (response.data.summary && response.data.summary.length > 0) {
        setSelectedEndName(response.data.summary[0].id);
      } else {
        setSelectedEndName(null);
      }
      setSelectedEndField(null); // Clear selected end field
    } catch (error) {
      console.error("Error fetching summary data:", error);
      toast.error("Failed to fetch summary data");
    } finally {
      setIsSummaryLoading(false);
    }
  }, [trackId]);

  useEffect(() => {
    fetchEmployeeDetails();
  }, [fetchEmployeeDetails]);

  useEffect(() => {
    if (employeeID) {
      fetchAssignedJobs();
      fetchTrackDetails();
      fetchSummaryData();
    }
  }, [fetchAssignedJobs, fetchTrackDetails, fetchSummaryData, employeeID]);

  useEffect(() => {
    // Add this to fetch the track's completion status
    axiosInstance
      .get(`${trackInitialization}${trackId}/`)
      .then((response) => {
        setTrackCompleted(response.data.is_done);
      })
      .catch((error) => {
        console.error("Error fetching track status:", error);
      });
  }, [trackId]);

  const handleAcceptJob = useCallback(
    async (jobId) => {
      const toastId = toast.loading("Accepting job...");
      try {
        await axiosInstance.patch(`${assignedJob}${jobId}/`, {
          status: "Accepted",
        });
        toast.update(toastId, {
          render: "Job accepted successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        fetchAssignedJobs(); // Refresh the job list
      } catch (error) {
        console.error("Error accepting job:", error);
        toast.update(toastId, {
          render: "Failed to accept job",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    },
    [fetchAssignedJobs]
  );

  const handleViewJob = useCallback(
    (job) => {
      if (job.task === "TC Assignee") {
        let approveId;
        switch (job.station) {
          case "End Document":
            approveId = job.ed_name;
            navigate(`/approve-job/${job.station}/${approveId}/${job.id}`);
            break;
          case "ED Field":
            approveId = job.ed_field;
            navigate(`/approve-ED/${approveId}/${job.id}`);
            break;
          case "PD Field":
            approveId = job.pd_field;
            navigate(`/approve-PD/${approveId}/${job.id}`);
            break;
          case "PD Element":
            approveId = job.pd_element;
            navigate(`/approve-PDElement/${approveId}/${job.id}`);
            break;
          default:
            toast.error("Invalid job configuration");
            return;
        }
      } else if (job.task === "Assignee") {
        switch (job.station) {
          case "End Document":
            navigate(`/assigned-jobs/${job.station}/${job.track}/${job.id}`);
            break;
          case "ED Field":
            navigate(`/end-fields/${job.ed_name}/${job.id}`);
            break;
          case "PD Field":
            navigate(`/predecessor-fields/${job.ed_field}/${job.id}`);
            break;
          case "PD Element":
            navigate(`/PD-element/${job.pd_field}/${job.id}`);
            break;
          default:
            toast.error("Invalid job configuration");
            return;
        }
      } else {
        toast.error("Invalid task type");
      }
    },
    [navigate]
  );

  const handleViewPredecessor = (predecessorId) => {
    setSelectedPredecessorId(predecessorId);
    setModalVisible(true);
  };

  const columns = useMemo(
    () => [
      {
        title: "Assignee",
        dataIndex: ["assignee_details", "employee_name"],
        key: "assignee",
      },
      {
        title: "Station",
        dataIndex: "station",
        key: "station",
      },
      {
        title: "Track",
        dataIndex: ["track_details", "name"],
        key: "track",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Task",
        dataIndex: "task",
        key: "task",
      },
      {
        title: "Action",
        key: "action",
        render: (_, record) => (
          <>
            {employeeDesignation !== "Team Leader" ? (
              record.status === "Completed" ? (
                <Button type="primary" onClick={() => handleViewJob(record)}>
                  View
                </Button>
              ) : record.status === "Accepted" ? (
                <Button type="primary" onClick={() => handleViewJob(record)}>
                  View
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={() => handleAcceptJob(record.id)}
                >
                  Accept
                </Button>
              )
            ) : (
              record.status === "Completed" && (
                <Button type="primary" onClick={() => handleViewJob(record)}>
                  View
                </Button>
              )
            )}
          </>
        ),
      },
    ],
    [handleAcceptJob, handleViewJob, employeeDesignation]
  );

  const currentStationColumns = [
    {
      title: "Name",
      dataIndex: ["assignee_details", "employee_name"],
      key: "name",
      width: "30%",
    },
    {
      title: "Station",
      dataIndex: "station",
      key: "station",
      width: "30%",
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
      width: "20%",
      render: (task) => {
        if (task === "Assignee") {
          return "Assignee";
        } else if (task === "TC Assignee") {
          return "TC";
        }
        return task; // Return the original value if it's neither "Assignee" nor "TC Assignee"
      },
    },
    {
      title: "Total Time ",
      key: "total_time",
      width: "10%",
      render: (_, record) => {
        const start = moment(record.start_time);
        const end = moment(record.end_time);
        const diffMinutes = end.diff(start, "minutes");

        if (isNaN(diffMinutes)) {
          return "-";
        } else if (diffMinutes < 60) {
          return `${diffMinutes} mins`;
        } else {
          const diffHours = Math.floor(diffMinutes / 60);
          const remainingMinutes = diffMinutes % 60;
          if (remainingMinutes === 0) {
            return `${diffHours} hrs`;
          } else {
            return `${diffHours} hrs ${remainingMinutes} mins`;
          }
        }
      },
    },
  ];

  const demoCostStatus = {
    totalRevenue: 0,
    totalCostTrack: 0,
    costStation: 0,
    actualCostBudget: 0,
    actualRevenueBudget: 0,
  };

  const clockStatusColumns = [
    {
      title: "Name",
      dataIndex: ["assignee_details", "employee_name"],
      key: "name",
      width: "20%",
    },
    {
      title: "Station",
      dataIndex: "station",
      key: "station",
      width: "15%",
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      width: "15%",
      render: (text) => {
        if (!text) {
          return "Not Started";
        }
        return moment(text).isValid()
          ? moment(text).format("DD-MM-YYYY HH:mm:ss")
          : "Not Started";
      },
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      width: "15%",
      render: (text) => {
        if (!text) {
          return "Not Ended";
        }
        return moment(text).isValid()
          ? moment(text).format("DD-MM-YYYY HH:mm:ss")
          : "Not Ended";
      },
    },
    {
      title: "Time to Accept Job",
      key: "time_to_accept",
      width: "15%",
      render: (_, record) => {
        const created = moment(record.created_at);
        const start = moment(record.start_time);
        const diffMinutes = start.diff(created, "minutes");

        if (isNaN(diffMinutes)) {
          return "-";
        } else if (diffMinutes < 60) {
          return `${diffMinutes} mins`;
        } else {
          const diffHours = Math.floor(diffMinutes / 60);
          return `${diffHours} hrs`;
        }
      },
    },
    {
      title: "Total Time",
      key: "total_time",
      width: "10%",
      render: (_, record) => {
        const start = moment(record.start_time);
        const end = moment(record.end_time);
        const diffMinutes = end.diff(start, "minutes");

        if (isNaN(diffMinutes)) {
          return "-";
        } else if (diffMinutes < 60) {
          return `${diffMinutes} mins`;
        } else {
          const diffHours = Math.floor(diffMinutes / 60);
          const remainingMinutes = diffMinutes % 60;
          if (remainingMinutes === 0) {
            return `${diffHours} hrs`;
          } else {
            return `${diffHours} hrs ${remainingMinutes} mins`;
          }
        }
      },
    },
    {
      title: "Average Time",
      key: "average_time",
      width: "10%",
      render: () => "-",
    },
  ];

  const handleCompleteTrack = () => {
    axiosInstance
      .patch(`${trackInitialization}${trackId}/`, { is_done: true })
      .then((response) => {
        setTrackCompleted(true);
        toast.success("Track completed successfully!");
        onTrackComplete();
      })
      .catch((error) => {
        console.error("Error completing track:", error);
        toast.error("Failed to complete track. Please try again.");
      });
  };

  const renderSummaryContent = () => {
    if (isSummaryLoading) {
      return <Spin size="large" />;
    }

    if (
      !summaryData ||
      !summaryData.summary ||
      summaryData.summary.length === 0
    ) {
      return <p>No summary data available.</p>;
    }

    const selectedEndDocument = summaryData.summary.find(
      (item) => item.id === selectedEndName
    );
    const endFields = selectedEndDocument?.end_fields || [];

    const handleEndFieldClick = (endField) => {
      setSelectedEndField(endField);
    };

    return (
      <Card className="summary-card">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div style={{ marginBottom: "8px" }}>
              <strong>End Document:</strong>
            </div>
            <Select
              style={{ width: "100%" }}
              placeholder="Select End Document"
              value={selectedEndName}
              onChange={(value) => {
                setSelectedEndName(value);
                setSelectedEndField(null);
              }}
            >
              {summaryData.summary.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.end_name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={12}>
            <Card
              title="End Fields"
              className="end-fields-card"
              bodyStyle={{ maxHeight: "400px", overflow: "auto" }}
            >
              <Table
                dataSource={endFields}
                columns={[
                  {
                    dataIndex: "field_name",
                    key: "field_name",
                    render: (text, record) => (
                      <Button
                        type="link"
                        onClick={() => handleEndFieldClick(record)}
                        style={{ textAlign: "left", padding: "4px 0" }}
                      >
                        {text}
                      </Button>
                    ),
                  },
                ]}
                rowKey="id"
                pagination={false}
                showHeader={false}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title="Predecessor Fields"
              className="predecessor-fields-card"
              bodyStyle={{ maxHeight: "400px", overflow: "auto" }}
            >
              {selectedEndField ? (
                <Table
                  dataSource={selectedEndField.predecessor_fields || []}
                  columns={[
                    {
                      dataIndex: "field_name",
                      key: "field_name",
                      render: (text, record) => (
                        <Tag
                          color={
                            record.id === selectedPredecessorId
                              ? "blue"
                              : "default"
                          }
                        >
                          {text}
                        </Tag>
                      ),
                    },
                    {
                      title: "Action",
                      key: "action",
                      render: (_, record) => (
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => handleViewPredecessor(record.id)}
                        >
                          View
                        </Button>
                      ),
                    },
                  ]}
                  rowKey="id"
                  pagination={false}
                />
              ) : (
                <p style={{ textAlign: "center", color: "#999" }}>
                  Select an End Field to view its Predecessor Fields
                </p>
              )}
            </Card>
          </Col>
        </Row>
        {employeeDesignation === "Team Leader" && (
          <Row style={{ marginTop: 16 }}>
            <Col span={24}>
              {trackCompleted ? (
                <Button type="primary" disabled>
                  Track Completed
                </Button>
              ) : (
                <Button type="primary" onClick={handleCompleteTrack}>
                  Complete Track
                </Button>
              )}
            </Col>
          </Row>
        )}
      </Card>
    );
  };

  return (
    <div className="track-management">
      <Tabs defaultActiveKey="1" className="modern-tabs">
        <TabPane tab="Dashboard" key="1">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title="Current Station" className="station-card">
                <Table
                  columns={currentStationColumns}
                  dataSource={assignedJobs}
                  pagination={false}
                  scroll={{ y: 240 }}
                  rowKey="id"
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card title="Cost Status" className="cost-status-card">
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Statistic
                      title="Total Revenue"
                      value={demoCostStatus.totalRevenue}
                      prefix="AED "
                      valueStyle={{ color: "#3f8600" }}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Total Cost/Track"
                      value={demoCostStatus.totalCostTrack}
                      prefix="AED "
                      valueStyle={{ color: "#cf1322" }}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Cost/Station"
                      value={demoCostStatus.costStation}
                      prefix="AED "
                      valueStyle={{ color: "#1890ff" }}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Actual Cost/Budget"
                      value={demoCostStatus.actualCostBudget}
                      suffix="%"
                      valueStyle={{
                        color:
                          demoCostStatus.actualCostBudget > 50
                            ? "#cf1322"
                            : "#3f8600",
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Actual Revenue/Budget"
                      value={demoCostStatus.actualRevenueBudget}
                      suffix="%"
                      valueStyle={{
                        color:
                          demoCostStatus.actualRevenueBudget > 50
                            ? "#3f8600"
                            : "#cf1322",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card title="Clock Status" className="clock-status-card">
                <Table
                  columns={clockStatusColumns}
                  dataSource={assignedJobs}
                  pagination={false}
                  scroll={{ y: 240 }}
                  rowKey="id"
                />
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Assigned Jobs" key="2">
          {isLoading ? (
            <div className="loading-spinner">
              <Spin size="large" />
            </div>
          ) : (
            <Card className="assigned-jobs-card">
              <Table
                columns={columns}
                dataSource={assignedJobs}
                rowKey={(record) => record.id}
                pagination={{ pageSize: 10 }}
                scroll={{ x: true }}
              />
            </Card>
          )}
        </TabPane>
        <TabPane tab="Summary" key="3">
          {renderSummaryContent()}
        </TabPane>
      </Tabs>

      <PDElementModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        predecessorId={selectedPredecessorId}
      />
    </div>
  );
};

export default TrackManagementTask;
