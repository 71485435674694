import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Card, Button, Spin, Typography, Descriptions } from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  endDocumentField,
  employeeDetails,
  assignedJob,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import Header from "../../../layout/Header";
import OperationSideBar from "../../../layout/OperationsSidebar";
import { TrackProvider } from "../../../../contexts/TrackContext";

const { Title, Text } = Typography;

const EDApproval = () => {
  const { id, jobId } = useParams();
  const [endDocument, setEndDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);

  const fetchEndDocumentDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${endDocumentField}${id}/`);
      setEndDocument(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching End Document details:", error);
      toast.error("Failed to fetch End Document details");
    } finally {
      setLoading(false);
    }
  }, [id]);

  const fetchEmployeeDetails = async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);
      setEmployeeId(response.data.employment_details[0].id);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Failed to fetch employee details");
    }
  };

  const handleApprove = async () => {
    setSubmitting(true);
    const toastId = toast.loading("Processing...");
    try {
      const payload = {
        tc_status: true,
        assignee_id: employeeId,
      };
      await axiosInstance.patch(`${endDocumentField}${id}/`, payload);
      if (jobId) {
        await axiosInstance.patch(`${assignedJob}${jobId}/`, {
          status: "Completed",
        });
      }
      toast.success("End Document Field approved successfully");
      fetchEndDocumentDetails();
    } catch (error) {
      console.error("Error approving End Document Field:", error);
      toast.error("Failed to approve End Document Field");
    } finally {
      setSubmitting(false);
      toast.dismiss(toastId);
    }
  };

  useEffect(() => {
    fetchEndDocumentDetails();
    fetchEmployeeDetails();
  }, [fetchEndDocumentDetails]);

  const hasAlreadyApproved =
    endDocument &&
    employeeId &&
    (endDocument.tc1_assignee === employeeId ||
      endDocument.tc2_assignee === employeeId);

  const isFullyApproved =
    endDocument?.tc1_assignee && endDocument?.tc2_assignee;

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <TrackProvider>
        <OperationSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid position-relative">
            <Card>
              <Title level={3}>Approve End Document Field</Title>
              <Descriptions bordered column={1}>
                <Descriptions.Item label="End Document Name">
                  <Text strong>
                    {endDocument?.end_document_details?.end_name}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Track">
                  <Text>{endDocument?.track_details?.name}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Department">
                  <Text>
                    {endDocument?.track_details?.department_details?.name}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="End Document Field">
                  <Text strong>{endDocument?.field_name}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  <Text
                    type={
                      endDocument?.status === "Approved" ? "success" : "warning"
                    }
                  >
                    {endDocument?.status}
                  </Text>
                </Descriptions.Item>
              </Descriptions>

              <Button
                type="primary"
                onClick={handleApprove}
                loading={submitting}
                disabled={isFullyApproved || hasAlreadyApproved}
                style={{ marginTop: "20px" }}
              >
                {isFullyApproved
                  ? "Already Approved"
                  : hasAlreadyApproved
                  ? "You have already approved"
                  : "Approve End Document Field"}
              </Button>
            </Card>
          </div>
        </div>
      </TrackProvider>
    </>
  );
};

export default EDApproval;
