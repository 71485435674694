import React, { useState } from "react";
import { Table, Button } from "antd";
import { Modal, Form } from "react-bootstrap";

const EndDocumentList = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  // Demo data for the table
  const dataSource = [
    {
      id: 1,
      track_name: "GACL",
      end_document_name: "Checklist",
      end_document_fields: [
        "Track Name",
        "End Document Names",
        "End Document Fields",
      ],
      predecessor_fields: "Track Name",
      predecessor_elements: "End Document Elements",
    },
  ];

  // Table columns
  const columns = [
    {
      title: "Track Name",
      dataIndex: "track_name",
      key: "track_name",
    },
    {
      title: "End Document Names",
      dataIndex: "end_document_name",
      key: "end_document_name",
    },
    {
      title: "End Document Fields",
      key: "end_document_fields",
      render: (text, record) => (
        <div>
          {record.end_document_fields.map((field, index) => (
            <div key={index}>{field}</div>
          ))}
        </div>
      ),
    },
    {
      title: "Predecessor Fields",
      dataIndex: "predecessor_fields",
      key: "predecessor_fields",
    },
    {
      title: "Predecessor Elements",
      dataIndex: "predecessor_elements",
      key: "predecessor_elements",
    },
    {
      title: "Add",
      key: "add",
      render: (text, record) => (
        <Button variant="warning" size="sm" onClick={() => handleAdd(record)}>
          Add
        </Button>
      ),
    },
    {
      title: "Submit",
      key: "submit",
      render: (text, record) => (
        <Button variant="danger" size="sm" onClick={() => handleSubmit(record)}>
          Submit
        </Button>
      ),
    },
  ];

  const handleAdd = (record) => {
    // Handle "Add" button functionality
    setSelectedRow(record);
    setShowModal(true);
  };

  const handleSubmit = (record) => {
    // Handle "Submit" button functionality
    console.log("Submitted record:", record);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedRow(null);
  };

  return (
    <div className="container">
      <Button variant="dark" className="mb-4">
        Predecessor Fields & Elements
      </Button>
      <Table
        className="table-striped"
        style={{ overflowX: "auto" }}
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => record.id}
      />

      {/* Modal for Add functionality */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Document Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Track Name</Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={selectedRow ? selectedRow.track_name : ""}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>End Document Name</Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={selectedRow ? selectedRow.end_document_name : ""}
              />
            </Form.Group>
            {/* You can add more fields as necessary */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => console.log("Add button clicked")}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EndDocumentList;
