import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Button,
  Spin,
  Typography,
  Descriptions,
  Row,
  Col,
  Divider,
  Tag,
} from "antd";
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  predecessorElement,
  employeeDetails,
  assignedJob,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import Header from "../../../layout/Header";
import OperationSideBar from "../../../layout/OperationsSidebar";
import { TrackProvider } from "../../../../contexts/TrackContext";

const { Title, Text } = Typography;

const PDElementApproval = () => {
  const { id, jobId } = useParams();
  const [pdElement, setPdElement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);

  const fetchPDElementDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${predecessorElement}${id}/`);
      console.log("Data", response.data);
      setPdElement(response.data);
    } catch (error) {
      console.error("Error fetching Predecessor Element details:", error);
      toast.error("Failed to fetch Predecessor Element details");
    } finally {
      setLoading(false);
    }
  }, [id]);

  const fetchEmployeeDetails = async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);
      setEmployeeId(response.data.employment_details[0].id);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Failed to fetch employee details");
    }
  };

  useEffect(() => {
    fetchPDElementDetails();
    fetchEmployeeDetails();
  }, [fetchPDElementDetails]);

  const handleApprove = async () => {
    setSubmitting(true);
    const toastId = toast.loading("Processing...");
    try {
      const payload = {
        tc_status: true,
        assignee_id: employeeId,
      };
      await axiosInstance.patch(`${predecessorElement}${id}/`, payload);
      if (jobId) {
        await axiosInstance.patch(`${assignedJob}${jobId}/`, {
          status: "Completed",
        });
      }
      toast.success("Predecessor Element approved successfully");
      fetchPDElementDetails();
    } catch (error) {
      console.error("Error approving Predecessor Element:", error);
      toast.error("Failed to approve Predecessor Element");
    } finally {
      setSubmitting(false);
      toast.dismiss(toastId);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const hasAlreadyApproved =
    pdElement &&
    employeeId &&
    (pdElement.tc1_assignee === employeeId ||
      pdElement.tc2_assignee === employeeId);

  const isFullyApproved = pdElement?.tc1_assignee && pdElement?.tc2_assignee;

  return (
    <>
      <Header />
      <TrackProvider>
        <OperationSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid position-relative">
            <Card
              title={
                <Title level={3}>
                  Predecessor Element Approval
                  <Tag
                    color={isFullyApproved ? "green" : "orange"}
                    style={{ marginLeft: 16 }}
                  >
                    {isFullyApproved ? (
                      <>
                        <CheckCircleOutlined /> Fully Approved
                      </>
                    ) : (
                      <>
                        <ClockCircleOutlined /> {pdElement?.status}
                      </>
                    )}
                  </Tag>
                </Title>
              }
              extra={
                <div>
                  <Button
                    type="primary"
                    onClick={handleApprove}
                    loading={submitting}
                    disabled={isFullyApproved || hasAlreadyApproved}
                    style={{ marginRight: 8 }}
                  >
                    {isFullyApproved
                      ? "Already Approved"
                      : hasAlreadyApproved
                      ? "You have already approved"
                      : "Approve Predecessor Element"}
                  </Button>
                </div>
              }
            >
              <Descriptions bordered column={2}>
                <Descriptions.Item label="End Document" span={2}>
                  <Text strong>
                    {
                      pdElement?.predecessor_details?.ed_field_details
                        ?.end_document_details?.end_name
                    }
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="End Field">
                  <Text>
                    {
                      pdElement?.predecessor_details?.ed_field_details
                        ?.field_name
                    }
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Predecessor Field">
                  <Text>{pdElement?.predecessor_details?.field_name}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Track">
                  <Text>{pdElement?.track_details?.name}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Department">
                  <Text>
                    {pdElement?.track_details?.department_details?.name}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="TC1 Status">
                  <Text type={pdElement?.tc1_assignee ? "success" : "warning"}>
                    {pdElement?.tc1_assignee ? "Approved" : "Pending"}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="TC2 Status">
                  <Text type={pdElement?.tc2_assignee ? "success" : "warning"}>
                    {pdElement?.tc2_assignee ? "Approved" : "Pending"}
                  </Text>
                </Descriptions.Item>
              </Descriptions>

              <Divider orientation="left">Element Details</Divider>

              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Card size="small" title="Assembly Line">
                    <Text>{pdElement?.assembly_line}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="End Document Number">
                    <Text>{pdElement?.end_document_number}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Field Code Number">
                    <Text>{pdElement?.field_code_number}</Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card size="small" title="Source Document">
                    <Text>{pdElement?.source_document}</Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card size="small" title="Action Sourcing">
                    <Text>{pdElement?.action_sourcing}</Text>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card size="small" title="Actions">
                    <Text>{pdElement?.actions}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Compliance">
                    <Text>{pdElement?.compliance}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Cost">
                    <Text>{pdElement?.cost}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Revenue">
                    <Text>{pdElement?.revenue}</Text>
                  </Card>
                </Col>
              </Row>

              <Divider orientation="left">Job Details</Divider>

              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Card size="small" title="Job Acceptance">
                    <Text>{pdElement?.job_acceptance}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Job Initiation">
                    <Text>{pdElement?.job_initiation}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Job Completion">
                    <Text>{pdElement?.job_completion}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Total Execution Time">
                    <Text>{pdElement?.total_execution_time}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Average Time">
                    <Text>{pdElement?.average_time}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Database">
                    <Text>{pdElement?.database}</Text>
                  </Card>
                </Col>
              </Row>

              <Divider orientation="left">Additional Details</Divider>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Card size="small" title="Accuracy Check">
                    <Text>{pdElement?.accuracy_check}</Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card size="small" title="Notification">
                    <Text>{pdElement?.notification}</Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card size="small" title="Outgoing Data">
                    <Text>{pdElement?.outgoing_data}</Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card size="small" title="Incoming Data">
                    <Text>{pdElement?.incoming_data}</Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card size="small" title="Data Field Format">
                    <Text>{pdElement?.data_field_format}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Meta Data">
                    <Text>{pdElement?.metadata}</Text>
                  </Card>
                </Col>
              </Row>

              <Divider orientation="left">Input and Output Information</Divider>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Card size="small" title="Input Data">
                    <Text>{pdElement?.input_data}</Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card size="small" title="Output Data">
                    <Text>{pdElement?.output_data}</Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card size="small" title="Input Source">
                    <Text>{pdElement?.input_source}</Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card size="small" title="Output Source">
                    <Text>{pdElement?.output_source}</Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card size="small" title="Input Field Number">
                    <Text>{pdElement?.input_field_number}</Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card size="small" title="Output Field Number">
                    <Text>{pdElement?.output_field_number}</Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card size="small" title="Document Status">
                    <Text>{pdElement?.document_status}</Text>
                  </Card>
                </Col>
              </Row>

              <Divider orientation="left">Requirements and Permissions</Divider>

              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Card size="small" title="Skills Required">
                    <Text>{pdElement?.skills_required}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Triggers">
                    <Text>{pdElement?.triggers}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Permissions">
                    <Text>{pdElement?.permissions}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Responsible Department">
                    <Text>{pdElement?.responsible_department}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Branch">
                    <Text>{pdElement?.branch}</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="System Action">
                    <Text>{pdElement?.system_action}</Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card size="small" title="Action Code">
                    <Text>{pdElement?.action_code}</Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card size="small" title="Events">
                    <Text>{pdElement?.events}</Text>
                  </Card>
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      </TrackProvider>
    </>
  );
};

export default PDElementApproval;
